import { logger } from '@beacon/common/lang/log'
import { distribution } from '@beacon/common/lang/util'
import { Providers } from '@beacon/common/providers'
import { requestListingUpdate } from '@beacon/common/services'
import { findActiveProviderListings } from '@beacon/common/services/findActiveProviderListings'
import { DateTime } from 'luxon'
import { _getFeed } from './_getFeed'
import { BoatsOnlineProvider } from './index'

const log = logger({ package: 'boats-online', f: 'refreshListings' })

/**
 * Issue refresh operations for all the current listings.
 *
 * @param bindings
 * @returns a Promise of the operations
 */
export const refreshListings = async (
  bindings: { FEED_FETCH_QUEUE: Queue; LISTING_ACTIVE: KVNamespace<string>; ID_CHART: KVNamespace },
  force = false,
): Promise<any> => {
  // how far to distribute the refreshes
  const SPREAD = 7200

  //
  // find the existing IDs list.
  const existingIds = await findActiveProviderListings(bindings, BoatsOnlineProvider.key)
  if (existingIds.length == 0) {
    log.info('no existing listings')
    return Promise.resolve([])
  }
  log.debug({ length: existingIds.length }, 'Existing Listings')

  // get the listings.
  const boats = await _getFeed()

  // create listing uri -> to the last updated time.
  const lastUpdates = new Map()
  boats.forEach((boat: any) => {
    // use whichever timestamp is newest.
    const when = Math.max(boat.LastDataUpdateTime, boat.LastImagesUpdateTime)
    if (!isNaN(when)) {
      lastUpdates.set(`${Providers.BOATS_ONLINE}::${boat['@_ad_id']}`, DateTime.fromSeconds(when).toISO())
    }
  })

  log.trace({ lastUpdates: Array.from(lastUpdates) }, 'Last Updates')

  //
  // Distribute the updates across the spread time
  const points = distribution(0, SPREAD, existingIds.length)
  const awaiting: Promise<any>[] = []
  for (let [idx, existingId] of existingIds.entries()) {
    //
    const optional = {
      ...(lastUpdates.has(existingId) && { lastUpdated: lastUpdates.get(existingId) }),
      delaySeconds: points[idx],
      force,
    }

    awaiting.push(requestListingUpdate(bindings, existingId, optional))
  }

  return Promise.allSettled(awaiting)
}

import { logger } from '@beacon/common/lang/log'

const log = logger({ package: 'services', f: 'findExistingListings' })

/**
 * Find existing listings for a provider
 *
 * @param bindings
 * @param providerKey the id of the provider
 * @returns an array of the existing provider_uri items
 */
export const findExistingListings = async (
  bindings: {
    ID_CHART: KVNamespace
  },
  providerKey: string,
): Promise<string[]> => {
  log.debug({ providerKey }, 'finding existing listings')

  const existingKeys = []
  //
  let complete = false
  let pageCursor = undefined
  do {
    // perform a prefix search on the ID_CHART KV.
    const results: any = await bindings.ID_CHART.list({
      prefix: `${providerKey}::`,
      limit: 1000,
      ...(pageCursor && { cursor: pageCursor }),
    })
    const keys = results.keys.map((key: { name: string }) => key.name)
    existingKeys.push(...keys)

    // are there more than 1000 keys?
    if (results.list_complete) {
      complete = true
    } else {
      pageCursor = results.cursor
    }
  } while (!complete)

  //
  return existingKeys
}

import { Asset, ListingContent } from '@beacon/common/types'
import { AhoyCMSListing } from './index'

export const extractAssets = (content: ListingContent<AhoyCMSListing>): Asset[] => {
  //
  let raw: AhoyCMSListing
  if (typeof content.body === 'string') {
    raw = JSON.parse(content.body)
  } else {
    raw = content.body
  }

  const urls: string[] = []
  if (raw.images) {
    urls.push(...raw.images)
  }

  if (raw.main_spec.main_image) {
    urls.push(raw.main_spec.main_image)
  }

  // make sure the asset list is unique.
  const set = new Set(urls)
  return Array.from(set).map((src) => ({ src }))
}

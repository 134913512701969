import { Asset, ListingContent } from '@beacon/common/types'
import { YatcoListing } from './index'

export const extractAssets = (content: ListingContent<YatcoListing>, filter?: any): Asset[] => {
  //
  let y: YatcoListing
  if (typeof content.body === 'string') {
    y = JSON.parse(content.body)
  } else {
    y = content.body
  }

  const imgs = new Set<string>()

  // The only filter supported is when it is "medium"
  // clearer to do it in a block.
  if (filter === 'medium') {
    imgs.add(y.Result.MainPhotoMedURL)
    for (const img of y.PhotoGallery) {
      imgs.add(img.mediumImageURL)
    }
    for (const img of y.Layouts) {
      imgs.add(img.mediumImageURL)
    }
  } else {
    // do not know what underlying references are used in descriptions, etc.
    // be sure to extract all image urls.
    imgs.add(y.Result.MainPhotoUrl)
    imgs.add(y.Result.MainPhotoMedURL)
    imgs.add(y.BasicInfo.MainPhotoURL)
    // skip: Broker info.
    // skip: Broker Company info.
    for (const img of y.PhotoGallery) {
      imgs.add(img.largeImageURL)
      imgs.add(img.mediumImageURL)
      imgs.add(img.smallImageURL)
    }
    //
    for (const img of y.Layouts) {
      imgs.add(img.largeImageURL)
      imgs.add(img.mediumImageURL)
      imgs.add(img.smallImageURL)
    }
  }

  // as Asset[]
  return (
    Array.from(imgs)
      // filter out empty strings
      .filter((src) => !!src)
      // map to asset
      .map((src) => ({ src }))
  )
}

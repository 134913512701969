import { ObjectNotFoundFault } from '@beacon/common/lang/faults'
import { logger } from '@beacon/common/lang/log'
import { ListingContent, ListingInfo } from '@beacon/common/types'
import { asPath } from './uri'

const log = logger({ package: 'services', f: 'getListingContent' })

/**
 * Get the raw content for a listing
 *
 * @param bindings
 * @param listing the ListingInfo type or the beacon uri to the listing
 * @returns the raw content of the listing
 */
export const getListingContent = async <T>(
  bindings: { BEACON: R2Bucket },
  listing: string | ListingInfo,
): Promise<ListingContent<T>> => {
  log.debug({ listing }, 'getting raw.json')

  //
  let uri: string
  if (typeof listing === 'string') {
    uri = listing
  } else {
    uri = listing.uri
  }

  //
  const r2Path = asPath(uri, 'raw.json')
  const r2Body: R2ObjectBody | null = await bindings.BEACON.get(r2Path)
  if (!r2Body) {
    throw new ObjectNotFoundFault(`${r2Path}`)
  }

  return {
    uri,
    body: await r2Body.json<T>(),
  }
}

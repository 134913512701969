import { round, toEpochSeconds } from '@beacon/common/lang/util'
import { AssetMap, Chronicle, IndexHit, ListingContent, ListingInfo, ListingState } from '@beacon/common/types'
import { BoatsOnlineListing } from './index'
import { logger } from '@beacon/common/lang/log'

const log = logger({ package: 'boats-online', f: 'asIndexHit' })

/**
 * Map an BoatsOnline item to an Algolia IndexItem
 *
 * @param info the listing information
 * @param raw the raw listing from the provider
 */
export const asIndexHit = (
  inf: ListingInfo,
  content: ListingContent<BoatsOnlineListing>,
  chron: Chronicle,
  assetMap: AssetMap,
): IndexHit => {
  let raw: BoatsOnlineListing
  if (typeof content.body === 'string') {
    raw = JSON.parse(content.body)
  } else {
    raw = content.body
  }

  const images = assetMap.map(raw.Resources?.Image)
  const imagesCount = images?.length || 0

  // NB: Keep the type shape for clarity in the code.
  const hit: IndexHit = {
    objectID: inf.uri,
    brochureId: inf.uriBrochure.split('::').pop()!,
    providerId: inf.uriProvider.split('::').pop()!,
    labels: raw.sold_time || raw.sold ? ['sold'] : [],
    length: round(raw.Length)!,
    model: raw.boatmodel,
    name: raw.VesselName?.trim() || raw.MakeModel,
    priceAsking: { amount: raw.Price, iso: raw.Currency },
    provider: inf.provider,
    //
    beam: round(raw.Beam),
    broker: {
      company: undefined,
      name: undefined,
      email: undefined,
      phoneNumber: undefined,
    },
    buildYear: raw.Year,
    builder: raw.boatmake,
    cabins: round(`${raw.Cabins}`, 0),
    // decksCount?: number // how many decks
    draft: round(raw.Draft),
    engineCount: raw.NumberEngines,
    engineHours: raw.EngineHours,
    // flag?: string // the flag of the yacht
    fuelCapacity: round(raw.FuelCapacity, 0),
    images: images,
    imagesCount: imagesCount,
    location: raw.Location,
    // locationCity?: string // the city where the yacht is located
    // locationCountry?: string // the country where the yacht is located
    // pricePrevious?: Price // Previous price of the yacht
    // refitType?: string // Type of refit
    // refitYear?: number // Year when the yacht was refitted
    speedCrusing: round(raw.CruiseSpeed),
    speedMax: round(raw.MaxSpeed),
    thumbnail: assetMap.get(raw.Thumbnail),
    tsChecked: toEpochSeconds(chron.lastChecked),
    tsListingPublished: raw.add_date,
    tsListingUpdated: raw.LastDataUpdateTime,
    tsSold: raw.sold_time,
    type: raw.cat_name,
    typeSub: raw.cat_secondary_name,
    //
    listing: raw.sold ? ListingState.SOLD : ListingState.LISTED,
  }

    // algolia records have a cap of 10kb, so we need to be careful with the size of the record.
  // if we need to remove some fields, we can do so now. (images)
  let passes = 0
  // while there are images to trim, the recod is too large, and we haven't tried too many times.
  while (hit.images?.length && JSON.stringify(hit).length > 10000 && passes++ < 500) {
    hit.images = hit.images?.slice(0, hit.images.length - 1)
  }
  const hitLength = JSON.stringify(hit).length
  if (hitLength > 10000) {
    log.error({ length: hitLength, uri: inf.uri }, 'record length is > 10k')
  }

  return hit
}

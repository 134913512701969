import { logger } from '@beacon/common/lang/log'
import { chunk } from '@beacon/common/lang/util'

const log = logger({ package: 'services', f: 'requestListingUpdate' })

/**
 * Request to update a listing
 *
 * @param bindings
 * @param uriListing the uri to the listing, can be a provider uri or a beacon uri
 * @param force force the feed to be fetched from the remote source
 * @param lastUpdated a hint to the fetcher when the listing was last updated
 * @param delaySeconds how long to wait before sending the update request.
 * @returns a Promise of the operation
 */
export const requestListingUpdate = async (
  bindings: {
    FEED_FETCH_QUEUE: Queue
  },
  uriListing: string | string[],
  {
    force = false,
    lastUpdated,
    lastChecked,
    delaySeconds = 0,
  }: { force?: boolean; lastUpdated?: string; lastChecked?: string; delaySeconds?: number } = {},
): Promise<any> => {
  //
  if (typeof uriListing == 'string') {
    uriListing = [uriListing]
  }

  // map to messages
  const msgs = uriListing.map((e) => ({
    body: { uriListing: e, force, lastUpdated, lastChecked },
  }))

  // send batches
  const awaiting = []
  const batches = chunk<MessageSendRequest>(msgs, 100)
  for (const batch of batches) {
    log.debug({ batch, force, lastUpdated, delaySeconds }, 'requesting to update listing(s)')
    awaiting.push(bindings.FEED_FETCH_QUEUE.sendBatch(batch, { delaySeconds }))
  }

  return Promise.all(awaiting)
}

import { createApp } from 'vue'
import InstantSearch from 'vue-instantsearch/vue3/es'
import VueObserveVisibility from 'vue-observe-visibility'

import { createAuthentication } from './plugin/authentication'
import router from './router'

import App from './App.vue'
import './style.css'

const Kinde = await createAuthentication({
  domain: 'https://ahoyclub.kinde.com',
  client_id: '72d1961dccc94106b1de8be6c8e0d8f5',
  redirect_uri: window.location.origin,
  org_code: 'org_53156f1b9b3',
})

// check on the token to ensure they are logged in.
const _token = await Kinde.getToken()
if (!_token) {
  // redirect to login if they are not.
  Kinde.redirectToLogin()
} else {
  const app = createApp(App)
  app.use(router)
  app.use(Kinde)
  app.use(InstantSearch)
  app.use(VueObserveVisibility)
  app.mount('#app')
  app.config.globalProperties.window = window 
}

import { DateTime } from 'luxon'
import { logger } from '@beacon/common/lang/log'

const log = logger({ package: 'common/lang', f: 'util' })

/**
 * Create a distribution of numbers based on the low/high/count.
 *
 * For example, if you want to spread 3 points between 0 and 100, you would call:
 * (0, 100, 3) => [23, 39, 72]
 * (-50, 50,3) => [-38, 7, 36]
 *
 * @param low the low number of the range
 * @param high the high number of the range
 * @param count  the number of points to spread
 * @returns an array of spread numbers
 */
export const distribution = (low: number, high: number, count: number) => {
  if (low == high) {
    throw new Error('low and high cannot be the same')
  }
  if (count < 1) {
    throw new Error('count must be one or more')
  }

  const spread = Math.floor((high - low) / count)
  const ret: number[] = []

  for (let left = low, right = low + spread; ret.length < count; left = right, right += spread) {
    let rnd = left + Math.floor(Math.random() * spread)
    ret.push(rnd)
  }

  return ret
}

/**
 * Convert a digest buffer to a hex string
 *
 * @param digest the digest
 * @returns the hex encoded string
 */
export const digestAsHex = (digest: ArrayBuffer) =>
  [...new Uint8Array(digest)].map((b) => b.toString(16).padStart(2, '0')).join('')

/**
 * Decode base64 to string
 *
 * @param str
 * @returns
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
 */
export const decodeBase64 = (str: string): string => {
  const binString: any = atob(str)
  const u = Uint8Array.from(binString, (m: any) => m.codePointAt(0))
  return new TextDecoder().decode(u)
}

/**
 * Split an array to chunks; equal sizes up to the last
 *
 * @param arr the array to split
 * @param maxNumElements the max number of elements to include
 * @returns an array of arrays; the chunk has equal sizes up to the last
 */
export const chunk = <T>(arr: T[], maxNumElements: number) => {
  //
  return Array.from(
    { length: Math.ceil(arr.length / maxNumElements) }, //
    (_v, i) => arr.slice(i * maxNumElements, i * maxNumElements + maxNumElements),
  )
}

/**
 * Round a number to a given number of fraction digits
 *
 * @param n the number
 * @param fractionDigits how many digits (default 2)
 * @returns a number rounded or undefined
 */
export const round = (n: number | string, fractionDigits: number = 2): number | undefined => {
  log.trace({ n, fractionDigits }, 'round')

  let _n: number
  // sometimes data is bad, so we need to check for string
  if(typeof n === 'string') {
    // tricky this is -- what should we remove before we try to parse?
    const nStr = n.replace(',', '')
    _n = Number.parseFloat(nStr)
  }
  else {
    _n = n
  }

  if( !_n) {
    return undefined
  }

  if( typeof _n === 'number' && isNaN(_n)) {
    return undefined
  }

  return Number.parseFloat(_n.toFixed(fractionDigits))
}

/**
 * Convert an ISO formatted date string to epoch seconds
 *
 * @param d an ISO formatted date string
 * @returns the epoch seconds, or undefined if supplied parameter is undefined or can not be parsed.
 */
export const toEpochSeconds = (d: string | undefined): number | undefined => {
  log.trace({ d }, 'toEpochSeconds')
  if (!d || d.length === 0) {
    return undefined
  }
  //
  try {
    return round(DateTime.fromISO(d).toSeconds(), 0)
  } catch (e) {
    return undefined
  }
}

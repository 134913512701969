import { logger } from '@beacon/common/lang/log'
import { distribution } from '@beacon/common/lang/util'
import { requestListingUpdate } from '@beacon/common/services'
import { findActiveProviderListings } from '@beacon/common/services/findActiveProviderListings'
import { AhoyCMSProvider } from './index'

const log = logger({ file: 'refreshListings' })

/**
 * Issue refresh operations for all the current listings.
 *
 * @param bindings
 */
export const refreshListings = async (
  bindings: {
    BEACON: R2Bucket
    FEED_FETCH_QUEUE: Queue
    LISTING_ACTIVE: KVNamespace<string>
    ID_CHART: KVNamespace
    GH_TOKEN: string
  },
  force = false,
): Promise<any> => {
  // how far to distribute the refreshes
  const SPREAD = 7200

  // find the existing IDs list.
  const existingIds = await findActiveProviderListings(bindings, AhoyCMSProvider.key)
  if (existingIds.length == 0) {
    log.info('no existing listings')
    return Promise.resolve([])
  }

  log.debug({ length: existingIds.length }, 'Existing Listings')

  // Distribute the updates across the spread time
  const points = distribution(0, SPREAD, existingIds.length)
  const awaiting: Promise<any>[] = []
  for (let [idx, existingId] of existingIds.entries()) {
    awaiting.push(requestListingUpdate(bindings, existingId, { delaySeconds: points[idx], force }))
  }

  return Promise.allSettled(awaiting)
}

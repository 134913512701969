import { ListingNotFoundFault } from '@beacon/common/lang/faults'
import { logger } from '@beacon/common/lang/log'
import { ListingContent } from '@beacon/common/types'
import { DateTime } from 'luxon'
import { _getFeed } from './_getFeed'
import { BoatsOnlineListing, BoatsOnlineProvider } from './index'

const log = logger({ f: 'fetchListingContent' })

/**
 * Fetch an individual listing for the given uri.
 *
 * @param _bindings empty bindings - not in use
 * @param uri the URI to fetch
 * @returns a string form of the listing, an object contianing alternate forms of the listing.
 * @throws ObjectNotFoundFault if the uri is not found in the provider.
 */
export const fetchListingContent = async (_bindings: {}, uri: string): Promise<ListingContent<BoatsOnlineListing>> => {
  //
  // get the xml as JSON
  const boats = await _getFeed()

  // find/map these feed to a uri.
  const boat = boats.find((boat: any) => uri === `${BoatsOnlineProvider.key}::${boat['@_ad_id']}`)
  if (!boat) {
    log.error({ uri }, 'listing not found')
    throw new ListingNotFoundFault(`uri not in provider: ${uri}`)
  }

  //
  const when = Math.max(boat.LastDataUpdateTime, boat.LastImagesUpdateTime)
  const lastModified = DateTime.fromSeconds(when).toISO()
  const listing = {
    uri: uri,
    body: JSON.stringify(boat),
    checksums: {
      lastModified: lastModified,
    },
  }

  // console.debug(`fetched listing ${uri}: ${JSON.stringify(listing)}`)
  return listing
}

import { logger } from '@beacon/common/lang/log'

const log = logger({ package: 'services', f: 'findActiveProviderListings' })

/**
 * Find the active listings for a provider
 *
 * @param bindings
 * @param provider the provider
 * @returns a list of the current listings
 */
export const findActiveProviderListings = async (
  bindings: { LISTING_ACTIVE: KVNamespace<string> },
  provider: string,
): Promise<string[]> => {
  log.debug({ provider }, 'findActiveProviderListings')

  const keys: string[] = []
  //
  let complete = false
  let pageCursor = undefined
  do {
    const results: any = await bindings.LISTING_ACTIVE
      // list the keys by prefix
      .list({
        prefix: `${provider}::`,
        limit: 1000,
        ...(pageCursor && { cursor: pageCursor }),
      })

    const resultKeys = results.keys.map((key: { name: string }) => key.name)
    keys.push(...resultKeys)

    // are there more keys?
    if (results.list_complete) {
      complete = true
    } else {
      pageCursor = results.cursor
    }
  } while (!complete)

  //
  return keys
}

import { round, toEpochSeconds } from '@beacon/common/lang/util'
import { AssetMap, Chronicle, IndexHit, ListingContent, ListingInfo, ListingState } from '@beacon/common/types'
import { DateTime } from 'luxon'
import { extractAssets } from './extractAssets'
import { YatcoListing } from './index'
import { logger } from '@beacon/common/lang/log'

const log = logger({ package: 'yatco', f: 'asIndexHit' })

export const asIndexHit = (
  inf: ListingInfo,
  content: ListingContent<YatcoListing>,
  chron: Chronicle,
  assetMap: AssetMap,
): IndexHit => {
  //
  let raw: YatcoListing
  if (typeof content.body === 'string') {
    raw = JSON.parse(content.body)
  } else {
    raw = content.body
  }

  const broker = raw.Brokers?.length ? raw.Brokers[0] : undefined
  const thumbnail = raw.PhotoGallery?.[0]?.smallImageURL || raw.BasicInfo?.MainPhotoURL
  const images = assetMap.map(extractAssets(content, 'medium').map((a) => a.src))
  const imagesCount = images.length

  // NB: Keep the type shape for clarity in the code.
  const hit: IndexHit = {
    objectID: inf.uri,
    brochureId: inf.uriBrochure.split('::').pop()!,
    providerId: inf.uriProvider.split('::').pop()!,
    labels: whenWasSold(raw) ? ['sold'] : [],
    length: round(raw.Dimensions.Length)!,
    model: raw.BasicInfo.Model,
    name: raw.BasicInfo.BoatName,
    priceAsking: { amount: raw.BasicInfo.AskingPrice, iso: raw.BasicInfo.Currency },
    provider: inf.provider,
    //
    beam: round(raw.Result.BeamMeters),
    broker: {
      company: broker?.CompanyName,
      name: `${broker?.FirstName} ${broker?.LastName}`,
      email: broker?.Email,
      phoneNumber: broker?.Phone,
    },
    buildYear: raw.BasicInfo.YearBuilt,
    builder: raw.BasicInfo.Builder || raw.Result.BuilderName,
    cabins: raw.BasicInfo.StateRooms,
    decksCount: undefined,
    draft: round(raw.Dimensions.MinDraftValue || raw.Result.DraftMinMeters),
    engineCount: raw.Engines?.length,
    engineHours: round((raw.Engines?.[0]).AppoxHours), // <-- note the typo from the provider
    flag: raw.BasicInfo.Flag,
    fuelCapacity: round(raw.SpeedWeight.FuelCapacityValue),
    images: images,
    imagesCount: imagesCount,
    location: `${raw.BasicInfo.LocationCity}, ${raw.BasicInfo.LocationCountry}`,
    locationCity: raw.BasicInfo.LocationCity,
    locationCountry: raw.BasicInfo.LocationCountry,
    pricePrevious: undefined,
    refitType: undefined,
    refitYear: raw.BasicInfo.RefitYear,
    speedCrusing: round(raw.SpeedWeight.CruiseSpeedValue),
    speedMax: round(raw.SpeedWeight.MaxSpeedValue),
    thumbnail: assetMap.get(thumbnail),
    tsChecked: toEpochSeconds(chron.lastChecked),
    tsListingPublished: toEpochSeconds(raw.Result.ActiveDate),
    tsListingUpdated: toEpochSeconds(chron.lastUpdated),
    tsSold: whenWasSold(raw),
    type: raw.BasicInfo.MainCategory || raw.Result.MainCategoryText,
    typeSub: raw.BasicInfo.SubCategory,
    // deprecated
    listing: whenWasSold(raw) ? ListingState.SOLD : ListingState.LISTED,
  }

  // algolia records have a cap of 10kb, so we need to be careful with the size of the record.
  // if we need to remove some fields, we can do so now. (images)
  let passes = 0
  // while there are images to trim, the recod is too large, and we haven't tried too many times.
  while (hit.images?.length && JSON.stringify(hit).length > 10000 && passes++ < 500) {
    hit.images = hit.images?.slice(0, hit.images.length - 1)
  }
  const hitLength = JSON.stringify(hit).length
  if (hitLength > 10000) {
    log.error({ length: hitLength, uri: inf.uri }, 'record length is > 10k')
  }

  return hit
}

// return epoch seconds or undefined if not sold.
const whenWasSold = (d: YatcoListing): number | undefined => {
  // if there is no sold date, then it is not sold.
  if (!d.Result.SoldDate) {
    return undefined
  }

  // Sometimes Sold dates are added, but the Active is after. These are not sold.
  // > "ActiveDate": "2021-11-11T23:52:08",
  // > "SoldDate":   "2020-04-06T16:00:00",
  const active: DateTime = DateTime.fromISO(d.Result.ActiveDate)
  const sold: DateTime = DateTime.fromISO(d.Result.SoldDate)

  // if the sold date is before the active date, then it is not sold.
  if (sold < active) {
    return undefined
  }

  return sold.toSeconds()
}

import { logger } from '@beacon/common/lang/log'
import { XMLParser } from 'fast-xml-parser'
import { URL_FEED } from './index'

const log = logger({ package: 'boats-online', f: 'getFeed' })

/**
 * Get an listing of the boats from the feed
 * @returns
 */
export const _getFeed = async () => {
  log.info({ URL_FEED }, 'Fetching feed')
  const response = await fetch(URL_FEED)
  const xml = await response.text()

  // parse attributes; we need the ad_id from the top level boat.
  // <boat ad_id=
  const parser = new XMLParser({ ignoreAttributes: false })
  const obj = parser.parse(xml)

  // return the list of the boats
  return obj.boats.boat
}

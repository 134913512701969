import { logger } from '@beacon/common/lang/log'
import { Chronicle } from '@beacon/common/types'
import { asPath } from './uri'

const log = logger({ package: 'services', f: 'getChronicle' })

/**
 * Get the Chronicle for the listing, empty if one does not exist.
 *
 * @param bindings
 * @param uriListing the beracon:: uri to the listing
 * @returns the chronicle, or an empty object if one does not exist.
 */
export const getChronicle = async (bindings: { BEACON: R2Bucket }, uriListing: string): Promise<Chronicle> => {
  log.debug({ uriListing }, 'getting chronicle.json')
  const chronicical: R2ObjectBody | null = await bindings.BEACON.get(asPath(uriListing, 'chronicle.json'))
  if (!chronicical) {
    return {}
  }

  return await chronicical.json()
}

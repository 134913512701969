import pino from 'pino'

const parent = (opts: any = {}) =>
  pino({
    browser: {
      asObject: true,
      formatters: {
        level(label, _number) {
          return { level: label.toUpperCase() }
        },
        log(object) {
          if (typeof object === 'object') {
            return JSON.stringify(object)
          }
          return object
        },
      },
    },
    enabled: true,
    level: opts.LOG_LEVEL || 'info',
    timestamp: pino.stdTimeFunctions?.isoTime,
    ...opts,
  })

export const logger = (fields: {}, opts = {}) => {
  return parent(opts).child(fields)
}

import { ListingProvider } from '@beacon/common/providers'
import { asBrochureListing } from './asBrochureListing'
import { asIndexHit } from './asIndexHit'
import { createNewListings } from './createNewListings'
import { extractAssets } from './extractAssets'
import { fetchListingContent } from './fetchListingContent'
import { refreshListings } from './refreshListings'

export const URL_FEED = 'https://brokers.yachthub.com/1_bluewater_v6/xml-feed-all.php?br_num=9634&access=HJaX!warG3u3j'

type BindingsProto = {
  BEACON: R2Bucket
  FEED_FETCH_QUEUE: Queue
  FEED_SCANNER_TRIGGER_QUEUE: Queue
  GH_TOKEN: string
  ID_CHART: KVNamespace
  LISTING_ACTIVE: KVNamespace<string>
}

export const BoatsOnlineProvider: ListingProvider<BindingsProto, BoatsOnlineListing> = {
  name: 'Boats Online',
  key: 'boats-online',
  //
  asBrochureListing,
  asIndexHit,
  createNewListings,
  extractAssets,
  fetchListingContent,
  refreshListings,
}

export type BoatsOnlineListing = {
  Accomodation: string
  add_date: number
  AirConditioning: string
  Ballast: string
  Beam: string
  Berths: string
  BilgePumps: string
  boatmake: string
  boatmake_boatsales: string
  boatmodel: string
  Builder: string
  Cabins: number
  cat_hull_type: string
  cat_name: string
  cat_name_summary: string
  cat_power_type: string
  cat_secondary_name: string
  cat_secondary_name_summary: string
  Country: string
  country_code: string
  Covers: string
  CruiseSpeed: string
  Currency: string
  DeckGear: string
  DecksMaterial: string
  descript: string
  Designer: string
  Dinghy: string
  Draft: string
  Electrics: string
  Electronics: string
  Engine: string
  EngineHours2: number
  EngineHours: number
  EngineMake: string
  Entertainment: string
  Epirb: string
  exp_date: number
  FireProtection: string
  Flares: string
  Freezer: string
  FuelCapacity: string
  FuelConsumption: string
  FuelType: string
  Galley: string
  Genset: string
  GroundTackle: string
  HIN: string
  HorsePower: string
  HotWaterSystem: string
  HullMaterial: string
  HullMaterialExtra: string
  LastDataUpdateTime: number
  LastImagesUpdateTime: number
  lastmodified: string
  Length: number
  LifeJackets: string
  LifeRaft: string
  Location: string
  MakeModel: string
  MakeModelExtra: string
  MaxSpeed: string
  new_boat: string
  NumberEngines: number
  Outboard: string
  Price: number
  PriceComment: string
  Propulsion: string
  Reference: string
  Refrigeration: string
  region: string
  RegistrationExpiry: string
  RegistrationNumber: string
  Remarks: string
  Rigging: string
  SafetyGear: string
  SailInventory: string
  Shower: string
  show_time: number
  sold: string
  sold_time: number
  special_listing: string
  stock_status: string
  Stove: string
  Survey: string
  Thrusters: string
  Thumbnail: string
  Toilet: number
  Trailer: string
  TrailerMake: string
  TrailerModel: string
  TrailerRego: string
  TrailerRegoExpiry: string
  TrailerVIN: string
  VesselName: string
  virt_tour: string
  WaterCapacity: string
  WaterMaker: string
  websiteintegration_code: string
  Weight: string
  Year: number
  yh_bol: string
  Resources: {
    Image: string[]
  }
}

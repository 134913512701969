import { Asset, ListingContent } from '@beacon/common/types'
import { BoatsOnlineListing } from './index'

/**
 * Extract the assets from the raw source.
 *
 * @param raw the raw listing
 * @returns an array of asset src within the content
 */
export const extractAssets = (content: ListingContent<BoatsOnlineListing>): Asset[] => {
  let raw: BoatsOnlineListing
  if (typeof content.body === 'string') {
    raw = JSON.parse(content.body)
  } else {
    raw = content.body
  }

  const images: string[] = []

  if (raw.Thumbnail) {
    images.push(raw.Thumbnail)
  }

  // "Image" can be a string or an array of strings.
  if (raw.Resources?.Image) {
    // It can be a weird object
    // > "Image": [
    // >   {
    // >     "#text": "https://imgs.yachthub.com/3/1/4/7/4/2/0_4.jpg",
    // >     "@_watermarked": "true"
    // >   },
    // >   ...
    // > ]
    if (Array.isArray(raw.Resources.Image) && raw.Resources.Image[0].hasOwnProperty('#text')) {
      images.push(...raw.Resources.Image.map((i: any) => i['#text']))
    }
    // It can be an array of strings
    else if (Array.isArray(raw.Resources.Image)) {
      images.push(...raw.Resources.Image)
    }
    // it's a string
    else {
      images.push(raw.Resources.Image)
    }
  }

  // make sure they are unique.
  const set = new Set(images)
  return Array.from(set).map((src) => ({ src }))
}

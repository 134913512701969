import { ListingContent } from '@beacon/common/types'
import { YatcoListing } from './index'
import { getVesselViewFullSpecsAllByMLSID } from './_api'

//
export const fetchListingContent = async (
  bindings: { YATCO_API_KEY: string },
  uri: string,
): Promise<ListingContent<YatcoListing>> => {
  //
  const uriParts = uri.split('::')
  const [_provider, _mls, mlsID] = uriParts

  //
  const response: YatcoListing = await getVesselViewFullSpecsAllByMLSID(bindings.YATCO_API_KEY, parseInt(mlsID))

  //
  const listing = {
    uri: uri,
    body: JSON.stringify(response),
    checksums: {
      lastModified: response.MiscInfo?.ModifiedDate || response.Result?.ModifiedDate,
    },
  }

  return listing
}

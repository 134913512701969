import * as yaml from 'js-yaml'
import { AhoyCMSListing } from './index'

/**
 * Convert "front matter" yaml to json
 *
 * @param contents
 * @returns a json object
 */
export const frontMatterYamlToJSON = (contents: string): AhoyCMSListing => {
  // split to the front matter and content
  const { frontMatter } = bisect(contents)
  const obj = yaml.load(frontMatter, { json: true })
  // console.debug(`frontMatterYamlToJSON: ${JSON.stringify(obj)}`)
  return obj as AhoyCMSListing
}

// split a file into front matter and content
const bisect = (contents: string) => {
  const parts = ['', '']
  const lines = contents.split('\n')
  let inFrontMatter = false

  for (let line of lines) {
    // did we hit a front matter marker?
    // log.debug(`> ${line}`)
    if (line === '+++' || line === '---') {
      inFrontMatter = !inFrontMatter
    } else if (inFrontMatter) {
      // add to the front matter
      parts[0] = `${parts[0]}\n${line}`
    } else {
      // add to content
      parts[1] = `${parts[1]}\n${line}`
    }
  }

  // convert the front matter text to an object
  return {
    frontMatter: parts[0],
    content: parts[1],
  }
}

<script setup lang="ts">
//
// This wraps the main app to put contexts outside the main app and layout
//
// It sets up:
//  1. an authentication context
//  2. an Algolia search client
//
// See also:
//  https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/js/
//  https://www.algolia.com/doc/api-reference/widgets/instantsearch/vue/
//

import algoliasearch from 'algoliasearch/lite'
import { history } from 'instantsearch.js/es/lib/routers'
import { simple } from 'instantsearch.js/es/lib/stateMappings'
import { inject } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

// Create the search client.
const authenticationContext: any = inject('authenticationContext')!
const algoliaAppId = 'ZGSGFGK2X2'
const authToken = await authenticationContext.getToken()
const searchApiKey = await fetch('/api/search/key', {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
  },
})
  .then((response) => response.json())
  .then((data: any) => data.key)

//
</script>
<template>
  <ais-instant-search
    :index-name="`global`"
    :search-client="algoliasearch(algoliaAppId, searchApiKey)"
    :routing="{
      router: history({
        writeDelay: 100,
        start(onUpdate) {
          router.afterEach((_to: any, _from: any, _failure: any) => {
            onUpdate()
            return true
          })
        },
      }),
      stateMapping: simple(),
      // or (to help with debugging)
      // stateMapping: {
      //   stateToRoute(uiState: any) {
      //     console.log('stateToRoute', uiState)
      //     return simple().stateToRoute(uiState)
      //   },
      //   routeToState(routeState: any) {
      //     console.log('routeToState', routeState)
      //     return simple().routeToState(routeState)
      //   },
      // },
    }"
  >
    <slot v-bind="$attrs"></slot>
  </ais-instant-search>
</template>

/**
 * A function that returns a boolean
 */
declare type Predicate = () => boolean

/**
 * The fault interface definition
 */
declare type Fault = {
  /**
   * The status code to represent this fault with
   */
  status: number
  /**
   * The reason for this fault to be thrown
   */
  reason: string
}

export class InternalFault extends Error {
  constructor(message = 'Internal Error') {
    super(message)
    this.name = 'InternalFault'
  }
}

// A Fault that can be type checked.
export class BadArgumentFault extends Error {
  constructor(message = 'Bad Argument') {
    super(message)
    this.name = 'BadArgumentFault'
  }
}

export class ObjectNotFoundFault extends Error {
  constructor(message = 'Not Found') {
    super(message)
    this.name = 'ObjectNotFoundFault'
  }
}

export class ListingNotFoundFault extends Error {
  uri = ''
  constructor(uri: string, message = 'Not Found') {
    super(message)
    this.uri = uri
    this.name = 'ListingNotFoundFault'
  }
}

/**
 * Build a fault given status code and a reason
 *
 * @param status the status code that maps to this fault
 * @param reason the reason for this fault to happen
 * @returns a fault object
 */
const buildFault = (status: number, reason: string): Fault => {
  return {
    status: status,
    reason: reason,
  }
}

/**
 * Checks if an argument is defined and throws if it isn't
 *
 * @param predicate the predicate to check the result for
 * @param errorMessage the message for the error to throw
 * @param errorType the type of Error to raise
 * @throws Error when the predicate evaluates to false
 */
export const affirmArgument = (predicate: Predicate, errorMessage?: string, errorType = BadArgumentFault): void => {
  if (!predicate()) {
    throw new errorType(errorMessage)
  }
}

/**
 *
 * @param message the reason that justifies this fault
 * @returns a response containing a fault as body
 */
export const NotFoundFault = (message: string = 'Not Found'): Response => {
  return new Response(JSON.stringify(buildFault(404, message)), {
    status: 404,
  })
}

import { AssetMap, BrochureListing, ListingContent, ListingInfo } from '@beacon/common/types'
import { AhoyCMSListing } from './index'

export const asBrochureListing = (
  _info: ListingInfo,
  content: ListingContent<AhoyCMSListing>,
  assetMap: AssetMap,
): BrochureListing => {
  //
  let raw: AhoyCMSListing
  if (typeof content.body === 'string') {
    raw = JSON.parse(content.body)
  } else {
    raw = content.body
  }

  // this in intentionally boilerplate to be able to see the changes.
  // ahoy-cms and the brochure should start to diverge.

  const listing = {
    //
    name: raw.name,
    title: raw.title,
    //
    auction_banner: raw.auction_banner,
    auction_date: raw.auction_date,
    auction_location: raw.auction_location,
    best_buy: raw.best_buy,
    bidding_link: raw.bidding_link,
    images: assetMap.map(raw.images),
    is_central_agency: raw.is_central_agency,
    new_listing: raw.new_listing,
    price_drop: raw.price_drop,
    sold_banner: Boolean(raw.sold_banner).valueOf(),
    accommodation: raw.accommodation,
    entertainment_and_av_equipment: raw.entertainment_and_av_equipment,
    galley_and_laundry_equipment: raw.galley_and_laundry_equipment,
    safety_and_security_equipment: raw.safety_and_security_equipment,
    refits_and_additional: raw.refits_and_additional,
    toys_and_tenders: raw.toys_and_tenders,
    //
    broker_details: {
      image: assetMap.get(raw.broker_details?.image),
      is_broker: raw.broker_details?.is_broker,
      job_title: raw.broker_details?.job_title,
      name: raw.broker_details?.name,
      personal_blurb: raw.broker_details?.personal_blurb,
      phone_number: raw.broker_details?.phone_number,
    },
    hull_and_deck: {
      date_keel_laid: raw.hull_and_deck?.date_keel_laid,
      deck_material: raw.hull_and_deck?.deck_material,
      exterior_designer: raw.hull_and_deck?.exterior_designer,
      hin: raw.hull_and_deck?.hin,
      hull_colour: raw.hull_and_deck?.hull_colour,
      hull_configuration: raw.hull_and_deck?.hull_configuration,
      hull_designer: raw.hull_and_deck?.hull_designer,
      hull_material: raw.hull_and_deck?.hull_material,
      hull_number: raw.hull_and_deck?.hull_number,
      interior_designer: raw.hull_and_deck?.interior_designer,
      superstructure_material: raw.hull_and_deck?.superstructure_material,
      warranty: raw.hull_and_deck?.warranty,
    },
    main_spec: {
      //
      length_meters: raw.main_spec.length_meters,
      model: raw.main_spec.model,
      price: raw.main_spec.price,
      description: raw.main_spec.description,
      year_built: raw.main_spec.year_built,
      //
      beam: raw.main_spec.beam,
      builder: raw.main_spec.builder,
      cabins: raw.main_spec.cabins,
      category: raw.main_spec.category,
      classification: raw.main_spec.classification,
      construction: raw.main_spec.construction,
      crew: raw.main_spec.crew,
      decks: raw.main_spec.decks,
      draft_length: raw.main_spec.draft_length,
      exterior_designer: raw.main_spec.exterior_designer,
      flag: raw.main_spec.flag,
      gross_tonnage: raw.main_spec.gross_tonnage,
      imo_number: raw.main_spec.imo_number,
      interior_designer: raw.main_spec.interior_designer,
      ism: raw.main_spec.ism,
      lying: raw.main_spec.lying,
      main_image: assetMap.get(raw.main_spec?.main_image),
      mci: raw.main_spec.mci,
      mmsi_number: raw.main_spec.mmsi_number,
      refit_type: raw.main_spec.refit_type,
      sleep: raw.main_spec.sleep,
      specifications: raw.main_spec.specifications,
      vessel_top: raw.main_spec.vessel_top,
      year_refit: raw.main_spec.year_refit,
    },
    mechanical_equipment: {
      air_compressor: raw.mechanical_equipment?.air_compressor,
      air_conditioning: raw.mechanical_equipment?.air_conditioning,
      antifouling_system: raw.mechanical_equipment?.antifouling_system,
      batteries: raw.mechanical_equipment?.batteries,
      battery_chargers: raw.mechanical_equipment?.battery_chargers,
      bow_thruster: raw.mechanical_equipment?.bow_thruster,
      dirty_oil_pump: raw.mechanical_equipment?.dirty_oil_pump,
      emergency_generator: raw.mechanical_equipment?.emergency_generator,
      engine_controls: raw.mechanical_equipment?.engine_controls,
      engine_mounts: raw.mechanical_equipment?.engine_mounts,
      engine_room_ventilation: raw.mechanical_equipment?.engine_room_ventilation,
      engine_ventilation: raw.mechanical_equipment?.engine_ventilation,
      engines_exhaust_system: raw.mechanical_equipment?.engines_exhaust_system,
      fresh_water_makers: raw.mechanical_equipment?.fresh_water_makers,
      fresh_water_pressure: raw.mechanical_equipment?.fresh_water_pressure,
      fresh_water_treatment: raw.mechanical_equipment?.fresh_water_treatment,
      fuel_filling_pump: raw.mechanical_equipment?.fuel_filling_pump,
      fuel_filters: raw.mechanical_equipment?.fuel_filters,
      fuel_filtration: raw.mechanical_equipment?.fuel_filtration,
      fuel_oil_separator: raw.mechanical_equipment?.fuel_oil_separator,
      fuel_transfer_pump: raw.mechanical_equipment?.fuel_transfer_pump,
      gear_boxes: raw.mechanical_equipment?.gear_boxes,
      generator_hours: raw.mechanical_equipment?.generator_hours,
      generators: raw.mechanical_equipment?.generators,
      grey_water_holding_tanks: raw.mechanical_equipment?.grey_water_holding_tanks,
      grey_water_system: raw.mechanical_equipment?.grey_water_system,
      hot_and_cold_circulation_pump: raw.mechanical_equipment?.hot_and_cold_circulation_pump,
      lubrication: raw.mechanical_equipment?.lubrication,
      oil_filtration: raw.mechanical_equipment?.oil_filtration,
      oil_pump: raw.mechanical_equipment?.oil_pump,
      output_electricity: raw.mechanical_equipment?.output_electricity,
      propellers: raw.mechanical_equipment?.propellers,
      propulsion_type: raw.mechanical_equipment?.propulsion_type,
      rudders: raw.mechanical_equipment?.rudders,
      sewage_system: raw.mechanical_equipment?.sewage_system,
      shaft_log_and_acking_glands: raw.mechanical_equipment?.shaft_log_and_acking_glands,
      shafting: raw.mechanical_equipment?.shafting,
      shore_power: raw.mechanical_equipment?.shore_power,
      stabilizers: raw.mechanical_equipment?.stabilizers,
      steering_system: raw.mechanical_equipment?.steering_system,
      stern_thruster: raw.mechanical_equipment?.stern_thruster,
      under_water_lights: raw.mechanical_equipment?.under_water_lights,
      wc: raw.mechanical_equipment?.wc,
      engine_1: {
        approx_hours: raw.mechanical_equipment?.engine_1?.approx_hours,
        date_hours_reg: raw.mechanical_equipment?.engine_1?.date_hours_reg,
        fuel: raw.mechanical_equipment?.engine_1?.fuel,
        horsepower: raw.mechanical_equipment?.engine_1?.horsepower,
        last_service: raw.mechanical_equipment?.engine_1?.last_service,
        manufacturer: raw.mechanical_equipment?.engine_1?.manufacturer,
        model: raw.mechanical_equipment?.engine_1?.model,
        serial: raw.mechanical_equipment?.engine_1?.serial,
        type: raw.mechanical_equipment?.engine_1?.type,
      },
      engine_2: {
        approx_hours: raw.mechanical_equipment?.engine_2?.approx_hours,
        date_hours_reg: raw.mechanical_equipment?.engine_2?.date_hours_reg,
        fuel: raw.mechanical_equipment?.engine_2?.fuel,
        horsepower: raw.mechanical_equipment?.engine_2?.horsepower,
        last_service: raw.mechanical_equipment?.engine_2?.last_service,
        manufacturer: raw.mechanical_equipment?.engine_2?.manufacturer,
        model: raw.mechanical_equipment?.engine_2?.model,
        serial: raw.mechanical_equipment?.engine_2?.serial,
        type: raw.mechanical_equipment?.engine_2?.type,
      },
    },
    navicom_equipment: {
      ais: raw.navicom_equipment?.ais,
      auto_pilot: raw.navicom_equipment?.auto_pilot,
      charts: raw.navicom_equipment?.charts,
      depth_sounder: raw.navicom_equipment?.depth_sounder,
      dgps: raw.navicom_equipment?.dgps,
      echo_sounder: raw.navicom_equipment?.echo_sounder,
      electronic_chart_system: raw.navicom_equipment?.electronic_chart_system,
      eprib: raw.navicom_equipment?.eprib,
      gdmss: raw.navicom_equipment?.gdmss,
      gps: raw.navicom_equipment?.gps,
      gyrocompass: raw.navicom_equipment?.gyrocompass,
      horn: raw.navicom_equipment?.horn,
      inmarsat_c: raw.navicom_equipment?.inmarsat_c,
      log: raw.navicom_equipment?.log,
      magnetic_compass: raw.navicom_equipment?.magnetic_compass,
      navigation_bridge: raw.navicom_equipment?.navigation_bridge,
      navtex: raw.navicom_equipment?.navtex,
      radar: raw.navicom_equipment?.radar,
      radio: raw.navicom_equipment?.radio,
      rudder_angle_indicator_system: raw.navicom_equipment?.rudder_angle_indicator_system,
      satcom: raw.navicom_equipment?.satcom,
      search_lights: raw.navicom_equipment?.search_lights,
      ships_computer: raw.navicom_equipment?.ships_computer,
      ships_monitoring: raw.navicom_equipment?.ships_monitoring,
      ssb: raw.navicom_equipment?.ssb,
      telephone_system: raw.navicom_equipment?.telephone_system,
      tender_tracking_system: raw.navicom_equipment?.tender_tracking_system,
      vdr: raw.navicom_equipment?.vdr,
      vhf: raw.navicom_equipment?.vhf,
    },
    speed_capacity_and_weight: {
      cruise_speed: raw.speed_capacity_and_weight?.cruise_speed,
      cruise_speed_range: raw.speed_capacity_and_weight?.cruise_speed_range,
      dirty_lube_oil: raw.speed_capacity_and_weight?.dirty_lube_oil,
      fresh_water_capacity: raw.speed_capacity_and_weight?.fresh_water_capacity,
      fuel_capacity: raw.speed_capacity_and_weight?.fuel_capacity,
      fuel_consumption: raw.speed_capacity_and_weight?.fuel_consumption,
      grey_black_water_capacity: raw.speed_capacity_and_weight?.grey_black_water_capacity,
      lube_oil: raw.speed_capacity_and_weight?.lube_oil,
      max_speed: raw.speed_capacity_and_weight?.max_speed,
      waster_oil: raw.speed_capacity_and_weight?.waster_oil,
    },
  }

  return listing
}

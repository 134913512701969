import { logger } from '@beacon/common/lang/log'
import { ListingInfo } from '@beacon/common/types'
import { asPath } from './uri'

const log = logger({ package: 'services', f: 'putListingInfo' })

/**
 * Write a feed document to the listing
 *
 * @param bindings
 * @param uriListing the beacon:: uri to the listing
 * @param listingInfo the feed to write
 * @returns the Promise of the write operation
 */
export const putListingInfo = async (
  bindings: { BEACON: R2Bucket },
  uriListing: string,
  listingInfo: ListingInfo,
): Promise<any> => {
  log.debug({ uriListing, listingInfo }, 'writing listing.json')
  return bindings.BEACON.put(asPath(uriListing, 'listing.json'), JSON.stringify(listingInfo))
}

import { transformPrice } from '@beacon/common/lang/price-separator'
import { round, toEpochSeconds } from '@beacon/common/lang/util'
import { AssetMap, Chronicle, IndexHit, ListingContent, ListingInfo, ListingState } from '@beacon/common/types'
import { AhoyCMSListing } from './index'
import { logger } from '@beacon/common/lang/log'

const log = logger({ package: 'ahoy-cms', f: 'asIndexHit' })

export const asIndexHit = (
  inf: ListingInfo,
  content: ListingContent<AhoyCMSListing>,
  chron: Chronicle,
  assetMap: AssetMap,
): IndexHit => {
  //
  let raw: AhoyCMSListing
  if (typeof content.body === 'string') {
    raw = JSON.parse(content.body)
  } else {
    raw = content.body
  }

  const images = assetMap.map(raw.images)
  const imagesCount = images?.length || 0

  // NB: Keep the type shape for clarity in the code.
  const hit: IndexHit = {
    objectID: inf.uri,
    brochureId: inf.uriBrochure.split('::').pop()!,
    providerId: inf.uriProvider.split('::').pop()!,
    labels: raw.show_on_sold || raw.sold_banner ? ['sold'] : [],
    length: round(raw.main_spec.length_meters)!,
    model: raw.main_spec.model,
    name: raw.name,
    priceAsking: transformPrice(raw.main_spec.price)!,
    provider: inf.provider,
    //
    beam: round(raw.main_spec.beam),
    broker: {
      company: undefined,
      name: raw.broker_details?.name,
      email: undefined,
      phoneNumber: raw.broker_details?.phone_number,
    },
    buildYear: raw.main_spec.year_built,
    builder: raw.main_spec.builder,
    cabins: raw.main_spec.cabins,
    decksCount: raw.main_spec.decks,
    draft: round(raw.main_spec.draft_length),
    engineCount: Object.keys(raw.mechanical_equipment || {}).filter((k) => k.startsWith('engine')).length,
    engineHours: round(Number.parseInt(raw.mechanical_equipment?.engine_1?.approx_hours), 0),
    flag: raw.main_spec.flag,
    fuelCapacity: round(Number.parseFloat(raw.speed_capacity_and_weight?.fuel_capacity?.replaceAll(',', ''))),
    images: images,
    imagesCount: imagesCount,
    location: raw.main_spec.lying,
    // locationCity?: string // the city where the yacht is located
    // locationCountry?: string // the country where the yacht is located
    // pricePrevious?: Price // Previous price of the yacht
    refitType: raw.main_spec.refit_type,
    refitYear: raw.main_spec.year_refit,
    speedCrusing: round(raw.speed_capacity_and_weight?.cruise_speed),
    speedMax: round(raw.speed_capacity_and_weight?.max_speed),
    thumbnail: assetMap.get(raw.main_spec.main_image),
    tsChecked: toEpochSeconds(chron.lastChecked),
    tsListingPublished: toEpochSeconds(chron.listed),
    tsListingUpdated: toEpochSeconds(chron.lastUpdated),
    // tsSold?: number // When was the vessel sold in epoch seconds
    type: raw.main_spec.category,
    typeSub: raw.main_spec.classification,
    //
    listing: raw.sold_banner ? ListingState.SOLD : ListingState.LISTED,
  }

  // algolia records have a cap of 10kb, so we need to be careful with the size of the record.
  // if we need to remove some fields, we can do so now. (images)
  let passes = 0
  // while there are images to trim, the recod is too large, and we haven't tried too many times.
  while (hit.images?.length && JSON.stringify(hit).length > 10000 && passes++ < 500) {
    hit.images = hit.images?.slice(0, hit.images.length - 1)
  }
  const hitLength = JSON.stringify(hit).length
  if (hitLength > 10000) {
    log.error({ length: hitLength, uri: inf.uri }, 'record length is > 10k')
  }

  return hit
}

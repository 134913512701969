import { Octokit } from '@octokit/rest'
import { decodeBase64 } from '@beacon/common/lang/util'

const GITHUB_OWNER = 'ahoyclub'
const GITHUB_REPO = 'homeport'

const octokit = (base64Auth: string) => new Octokit({ auth: decodeBase64(base64Auth) })

// See the docs for example responses;
//  https://docs.github.com/en/rest/repos/contents?apiVersion=2022-11-28

/**
 * Get a blob by its sha
 * @param sha the sha
 * @returns see Github API docs
 */
// export const getBySHA = async (bindings: { GH_TOKEN: string }, sha: string) => {
//   // console.debug(`getting content by sha: ${sha}`)
//   //
//   return octokit(bindings.GH_TOKEN).request('GET /repos/{owner}/{repo}/git/blobs/{file_sha}', {
//     owner: GITHUB_OWNER,
//     repo: GITHUB_REPO,
//     file_sha: sha,
//     headers: { 'X-GitHub-Api-Version': '2022-11-28' },
//   })
// }

/**
 * Get content by path
 *
 * @param path the path to the content
 * @param ref the branch or ref to get the content from
 * @returns see Github API docs
 */
export const getByPath = async (bindings: { GH_TOKEN: string }, path: string, ref = 'main') => {
  // console.debug(`getting content: ${path}`)
  //
  return octokit(bindings.GH_TOKEN).request('GET /repos/{owner}/{repo}/contents/{path}?ref={ref}', {
    owner: GITHUB_OWNER,
    repo: GITHUB_REPO,
    path: path,
    ref: ref,
    headers: { 'X-GitHub-Api-Version': '2022-11-28' },
  })
}

/**
 * Get a tree by its sha
 *
 * @param sha the sha
 * @param recursive to recursively get the tree, default is true
 * @returns see Github API docs
 */
export const getTree = async (bindings: { GH_TOKEN: string }, sha: string, recursive = true) => {
  // console.debug(`getting tree sha: ${sha}`)
  //
  return octokit(bindings.GH_TOKEN).request('GET /repos/{owner}/{repo}/git/trees/{tree_sha}?recursive={recursive}', {
    owner: GITHUB_OWNER,
    repo: GITHUB_REPO,
    recursive: recursive,
    tree_sha: sha,
    headers: { 'X-GitHub-Api-Version': '2022-11-28' },
  })
}

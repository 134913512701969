import { logger } from '@beacon/common/lang/log'
import { Chronicle } from '@beacon/common/types'
import { asPath } from './uri'

const log = logger({ package: 'services', f: 'putChronicle' })

/**
 * Write a chronicle to the listing
 *
 * @param bindings
 * @param uriListing the beacon:: uri to the listing
 * @param chronicle the Chronicle to write
 * @returns
 */
export const putChronicle = async (
  bindings: { BEACON: R2Bucket },
  uriListing: string,
  chronicle: Chronicle,
): Promise<any> => {
  log.debug({ uriListing, chronicle }, 'writing chronicle.json')
  return bindings.BEACON.put(asPath(uriListing, 'chronicle.json'), JSON.stringify(chronicle))
}

import { logger } from '@beacon/common/lang/log'
import { fetchActiveURIsFromYatco, searchYatcoListings } from './_helper'
import { createListing, findExistingListings } from '@beacon/common/services'
import { YatcoProvider } from './index'

const log = logger({ package: 'YatcoProvider', f: 'createNewListings' })

//
export const createNewListings = async (bindings: {
  BEACON: R2Bucket
  FEED_FETCH_QUEUE: Queue
  FEED_SCANNER_TRIGGER_QUEUE: Queue
  ID_CHART: KVNamespace
  YATCO_API_KEY: string
}): Promise<any> => {
  //
  const [activeMLSURIs, searchMLSURIs, activeBeaconURIs] = await Promise.all([
    // Get Active Vessel MLSIDs from YATCO api.
    fetchActiveURIsFromYatco(bindings.YATCO_API_KEY),
    // Use search to find other vessels not listed in the above.
    searchYatcoListings(bindings.YATCO_API_KEY, {}),
    // Get Beacon active URIs
    findExistingListings(bindings, YatcoProvider.key),
  ])

  //log.info({ mlsLength: activeMLSURIs.length, beacon: activeBeaconURIs.length }, 'this many')

  // Compare
  const _activeMLSURIs = new Set([...activeMLSURIs, ...searchMLSURIs])
  const _activeBeaconURIs = new Set(activeBeaconURIs)
  const newListingIds: any[] = Array.from(_activeMLSURIs.difference(_activeBeaconURIs))
  log.info(
    {
      newListingIds: newListingIds.length,
      activeMLSURIs: activeMLSURIs.length,
      searchMLSURIs: searchMLSURIs.length,
      _activeMLSURIs: _activeMLSURIs.size,
      _activeBeaconURIs: _activeBeaconURIs.size,
    },
    'discovery',
  )

  log.info({ n: newListingIds.length }, 'Number of new listings')
  return createListing(bindings, YatcoProvider.key, newListingIds)
}

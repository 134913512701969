import { AssetMap, BrochureListing, ListingContent, ListingInfo } from '@beacon/common/types'
import { YatcoListing } from './index'

export const asBrochureListing = (
  _inf: ListingInfo,
  content: ListingContent<YatcoListing>,
  assetMap: AssetMap,
): BrochureListing => {
  //
  let d: YatcoListing
  if (typeof content.body === 'string') {
    d = JSON.parse(content.body)
  } else {
    d = content.body
  }

  const b: BrochureListing = {
			//
			name: d.BasicInfo.BoatName,
			title: d.BasicInfo.BoatName,
			//
			auction_banner: undefined,
			auction_date: undefined,
			auction_location: undefined,
			best_buy: undefined,
			bidding_link: undefined,
			images: d.PhotoGallery.map((p) => p.largeImageURL),
			is_central_agency: undefined,
			new_listing: undefined,
			price_drop: undefined,
			sold_banner: d.BasicInfo.SoldPrice ? true : undefined,
			accommodation: undefined,
			entertainment_and_av_equipment: undefined,
			galley_and_laundry_equipment: undefined,
			safety_and_security_equipment: undefined,
			refits_and_additional: undefined,
			toys_and_tenders: undefined,
			weight: d.SpeedWeight.GrossTonnageValue || undefined,
			//
			broker_details: {
				// FIXME: this is not the listing broker, it is AHOY's broker
				image: undefined,
				is_broker: undefined,
				job_title: undefined,
				name: undefined,
				personal_blurb: undefined,
				phone_number: undefined,
			},
			hull_and_deck: {
				date_keel_laid: undefined,
				deck_material: d.HullDeck.DeckMaterial || undefined,
				exterior_designer: d.HullDeck.ExteriorDesigner || undefined,
				hin: undefined,
				hull_colour: d.HullDeck.HullColor || undefined,
				hull_configuration: d.HullDeck.HullConfiguration || undefined,
				hull_designer: d.HullDeck.HullDesigner || undefined,
				hull_material: d.HullDeck.HullMaterial || undefined,
				hull_number: d.HullDeck.HullID || undefined,
				interior_designer: d.HullDeck.InteriorDesigner || undefined,
				superstructure_material: d.HullDeck.SuperstructureMaterial || undefined,
				warranty: undefined,
			},
			main_spec: {
				//
				length_meters: d.Dimensions.Length || undefined,
				model: d.BasicInfo.Model || undefined,
				price: d.BasicInfo.AskingPriceFormatted || undefined,
				//
				beam: d.Dimensions.BeamValue || undefined,
				builder: d.BasicInfo.Builder || undefined,
				cabins: d.BasicInfo.StateRooms || undefined,
				category: d.Result.MainCategoryText || undefined,
				classification: undefined,
				construction: d.HullDeck.HullMaterial || undefined,
				crew: d.Accommodations.CrewSleepsValue || undefined,
				decks: undefined,
				description: d.Result.BrokerTeaser || undefined,
				draft_length: d.Dimensions.MinDraftValue || undefined,
				exterior_designer: d.HullDeck.ExteriorDesigner || undefined,
				flag: d.BasicInfo.Flag || undefined,
				gross_tonnage: d.SpeedWeight.GrossTonnageValue || undefined,
				imo_number: undefined,
				interior_designer: d.HullDeck.InteriorDesigner || undefined,
				ism: undefined,
				lying: [d.BasicInfo.LocationCity, d.BasicInfo.LocationCountry].filter(e => e).join(', '),
				main_image: assetMap.get(d.BasicInfo.MainPhotoURL),
				mci: undefined,
				mmsi_number: undefined,
				refit_type: undefined,
				sleep: d.Accommodations.SleepsValue || undefined,
				specifications: undefined,
				vessel_top: undefined,
				year_built: d.BasicInfo.YearBuilt || undefined,
				year_refit: undefined,
			},
			mechanical_equipment: {
			  air_compressor: undefined,
			  air_conditioning: undefined,
			  antifouling_system: undefined,
			  batteries: undefined,
			  battery_chargers: undefined,
			  bow_thruster: undefined,
			  dirty_oil_pump: undefined,
			  emergency_generator: undefined,
			  engine_controls: undefined,
			  engine_mounts: undefined,
			  engine_room_ventilation: undefined,
			  engine_ventilation: undefined,
			  engines_exhaust_system: undefined,
			  fresh_water_makers: undefined,
			  fresh_water_pressure: undefined,
			  fresh_water_treatment: undefined,
			  fuel_filling_pump: undefined,
			  fuel_filters: undefined,
			  fuel_filtration: undefined,
			  fuel_oil_separator: undefined,
			  fuel_transfer_pump: undefined,
			  gear_boxes: undefined,
			  generator_hours: undefined,
			  generators: undefined,
			  grey_water_holding_tanks: undefined,
			  grey_water_system: undefined,
			  hot_and_cold_circulation_pump: undefined,
			  lubrication: undefined,
			  oil_filtration: undefined,
			  oil_pump: undefined,
			  output_electricity: undefined,
			  propellers: undefined,
			  propulsion_type: undefined,
			  rudders: undefined,
			  sewage_system: undefined,
			  shaft_log_and_acking_glands: undefined,
			  shafting: undefined,
			  shore_power: undefined,
			  stabilizers: undefined,
			  steering_system: undefined,
			  stern_thruster: undefined,
			  under_water_lights: undefined,
			  wc: undefined,
			  engine_1: {
			    approx_hours: d.Engines[0]?.AppoxHours ? `${d.Engines[0].AppoxHours}` : undefined,
			    date_hours_reg: d.Engines[0]?.DateHoursReg || undefined,
			    fuel: d.Engines[0]?.FuelType || undefined,
			    horsepower: d.Engines[0]?.Horsepower ? `${d.Engines[0].Horsepower}` : undefined,
			    last_service: undefined,
			    manufacturer: d.Engines[0]?.Manufacturer || undefined,
			    model: d.Engines[0]?.Model || undefined,
			    serial: undefined,
			    type: d.Engines[0]?.EngineType || undefined,
			  },
			  engine_2: {
			    approx_hours: d.Engines[1]?.AppoxHours ? `${d.Engines[1].AppoxHours}` : undefined,
			    date_hours_reg: d.Engines[1]?.DateHoursReg || undefined,
			    fuel: d.Engines[1]?.FuelType || undefined,
			    horsepower: d.Engines[1]?.Horsepower ? `${d.Engines[1].Horsepower}` : undefined,
			    last_service: undefined,
			    manufacturer: d.Engines[1]?.Manufacturer || undefined,
			    model: d.Engines[1]?.Model || undefined,
			    serial: undefined,
			    type: d.Engines[1]?.EngineType || undefined,
			  }
			},
			navicom_equipment: {
			  ais: undefined,
			  auto_pilot: undefined,
			  charts: undefined,
			  depth_sounder: undefined,
			  dgps: undefined,
			  echo_sounder: undefined,
			  electronic_chart_system: undefined,
			  eprib: undefined,
			  gdmss: undefined,
			  gps: undefined,
			  gyrocompass: undefined,
			  horn: undefined,
			  inmarsat_c: undefined,
			  log: undefined,
			  magnetic_compass: undefined,
			  navigation_bridge: undefined,
			  navtex: undefined,
			  radar: undefined,
			  radio: undefined,
			  rudder_angle_indicator_system: undefined,
			  satcom: undefined,
			  search_lights: undefined,
			  ships_computer: undefined,
			  ships_monitoring: undefined,
			  ssb: undefined,
			  telephone_system: undefined,
			  tender_tracking_system: undefined,
			  vdr: undefined,
			  vhf: undefined,
			},
			speed_capacity_and_weight: {
			  cruise_speed: d.SpeedWeight.CruiseSpeedValue || undefined,
			  cruise_speed_range: d.SpeedWeight.CruiseSpeedRange || undefined,
			  dirty_lube_oil: undefined,
			  fresh_water_capacity: undefined,
			  fuel_capacity: d.SpeedWeight.FuelCapacity || undefined,
			  fuel_consumption: d.SpeedWeight.FuelConsumption || undefined,
			  grey_black_water_capacity: undefined,
			  lube_oil: undefined,
			  max_speed: d.SpeedWeight.MaxSpeedValue || undefined,
			  waster_oil: undefined
			}
		}

  //
  return b
}

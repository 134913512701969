import { logger } from '@beacon/common/lang/log'
import { createListing, findExistingListings } from '@beacon/common/services'
import { getCurrentListingURIs } from './_getCurrentListingURIs'
import { AhoyCMSProvider } from './index'

const log = logger({ package: 'ahoy-cms', f: 'createNewListings' })

/**
 * Create new Listings from the AhoyCMS
 *
 * @param bindings
 */
export const createNewListings = async (bindings: {
  BEACON: R2Bucket
  FEED_FETCH_QUEUE: Queue
  FEED_SCANNER_TRIGGER_QUEUE: Queue
  ID_CHART: KVNamespace
  GH_TOKEN: string
}): Promise<any> => {
  // get all the listings availability
  const entries = await getCurrentListingURIs(bindings)
  const providerListingUris = entries.map((e) => e.uri)

  //
  // This feed does not have the ability to find just "new" listings;
  //  find the existing listings and compare.
  const existingListings = await findExistingListings(bindings, AhoyCMSProvider.key)
  log.debug({ existingListings: Array.from(existingListings) }, 'Existing Listings')

  // Only new listings
  const newListingIds = providerListingUris.filter((listing) => !existingListings.includes(listing))
  log.info({ newListingIds }, 'New Listings')

  // create listings for the new listings
  const awaiting: Promise<any>[] = []
  for (const providerListingUri of newListingIds) {
    awaiting.push(createListing(bindings, AhoyCMSProvider.key, providerListingUri))
  }

  return Promise.all(awaiting)
}

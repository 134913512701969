import { logger } from '@beacon/common/lang/log'
import { chunk } from '@beacon/common/lang/util'

const log = logger({ package: 'services', f: 'createListing' })

/**
 * Create a new listing on Beacon
 *
 * @param bindings
 * @param providerKey the provider id
 * @param uri the new listing uri to the provider
 * @returns a Promise of all the operations
 */
export const createListing = async (
  bindings: { FEED_SCANNER_TRIGGER_QUEUE: Queue },
  providerKey: string,
  uri: string | string[],
): Promise<any> => {
  if (typeof uri == 'string') {
    uri = [uri]
  }

  //
  const msgs: MessageSendRequest[] = uri.map((e) => ({
    body: { action: 'createListing', provider: providerKey, uri: e },
  }))

  const batches = chunk<MessageSendRequest>(msgs, 100)
  log.debug({ n: msgs.length, k: batches.length }, 'creating n listings, k batches')

  const awaiting: Promise<any>[] = []
  for (const batch of batches) {
    awaiting.push(bindings.FEED_SCANNER_TRIGGER_QUEUE.sendBatch(batch))
  }
  return Promise.all(awaiting)
}

import { logger } from '@beacon/common/lang/log'
import { createListing, findExistingListings } from '@beacon/common/services'
import { _getFeed } from './_getFeed'
import { BoatsOnlineProvider } from './index'

const log = logger({ package: 'BoatsOnlineProvider', f: 'createNewListings' })

/**
 * Find new listings for this provider
 *
 * @param bindings
 * @returns a Promise of all the operations
 */
export const createNewListings = async (bindings: {
  BEACON: R2Bucket
  FEED_FETCH_QUEUE: Queue
  FEED_SCANNER_TRIGGER_QUEUE: Queue
  ID_CHART: KVNamespace
}): Promise<any> => {
  //
  // get the xml as JSON
  const boats = await _getFeed()

  // Create URIs out of all the items in the listing.
  const providerListingUris = boats.map((boat: any) => `${BoatsOnlineProvider.key}::${boat['@_ad_id']}`)
  log.debug({ providerListingUris: providerListingUris }, 'Provider Listings')

  //
  // This feed does not have the ability to find just "new" listings;
  //  find the existing listings and compare.
  const existingListings = await findExistingListings(bindings, BoatsOnlineProvider.key)
  log.debug({ existingIds: Array.from(existingListings) }, 'Existing Listings')

  // filter out potential new listings
  const newListingIds = providerListingUris.filter((listing: any) => !existingListings.includes(listing))
  log.info({ newListingIds }, 'New Listings')

  // create listings for the new listings
  const awaiting: Promise<any>[] = []
  for (const providerListingUri of newListingIds) {
    awaiting.push(createListing(bindings, BoatsOnlineProvider.key, providerListingUri))
  }
  return Promise.all(awaiting)
}

import { ListingProvider } from '@beacon/common/providers'
import { asBrochureListing } from './asBrochureListing'
import { asIndexHit } from './asIndexHit'
import { createNewListings } from './createNewListings'
import { extractAssets } from './extractAssets'
import { fetchListingContent } from './fetchListingContent'
import { refreshListings } from './refreshListings'

type BindingsProto = {
  BEACON: R2Bucket
  FEED_FETCH_QUEUE: Queue
  FEED_SCANNER_TRIGGER_QUEUE: Queue
  GH_TOKEN: string
  ID_CHART: KVNamespace
  LISTING_ACTIVE: KVNamespace<string>
}

export const AhoyCMSProvider: ListingProvider<BindingsProto, AhoyCMSListing> = {
  name: 'Ahoy Club',
  key: 'ahoy-cms',
  //
  asBrochureListing,
  asIndexHit,
  createNewListings,
  extractAssets,
  fetchListingContent,
  refreshListings,
}

export type AhoyCMSListing = {
  //
  accommodation: string
  auction_banner: string
  auction_date: string
  auction_location: string
  best_buy: boolean
  bidding_link: string
  comments: string
  draft: boolean // Hugo paramater for if the page is a draft (not available for the user) or not.
  entertainment_and_av_equipment: string
  galley_and_laundry_equipment: string
  images: string[]
  is_central_agency: boolean
  name: string
  new_listing: boolean
  price_drop: boolean
  refits_and_additional: string
  safety_and_security_equipment: string
  sales_announcement_link: string
  show_on_auctions: boolean
  show_on_best_buys: boolean
  show_on_new_listings: boolean
  show_on_price_drop: boolean
  show_on_sold: boolean
  sold_banner: string
  title: string
  toys_and_tenders: string
  vesselId: string // Was mainly used because Yatco API, not used at the moment.
  //
  broker_details: {
    name: string
    job_title: string
    image: string
    phone_number: string // e.g. "+44 1234 567890"
    personal_blurb: string
    is_broker: boolean
  }
  main_spec: {
    beam: number
    builder: string
    cabins: number
    category: string
    classification: string
    construction: string
    crew: number
    decks: number
    description: string
    draft_length: number
    exterior_designer: string
    flag: string
    gross_tonnage: number
    imo_number: string
    interior_designer: string
    ism: string
    length_meters: number
    lying: string
    main_image: string
    mci: string
    mmsi_number: string
    model: string
    price: string
    refit_type: string
    sleep: number
    specifications: string
    vessel_top: string
    year_built: number
    year_refit: number
  }
  speed_capacity_and_weight: {
    cruise_speed: number
    cruise_speed_range: string
    dirty_lube_oil: string
    fresh_water_capacity: string
    fuel_capacity: string
    fuel_consumption: string
    grey_black_water_capacity: string
    lube_oil: string
    max_speed: number
    waster_oil: string
  }
  hull_and_deck: {
    date_keel_laid: string
    deck_material: string
    exterior_designer: string
    hin: string
    hull_colour: string
    hull_configuration: string
    hull_designer: string
    hull_material: string
    hull_number: string
    interior_designer: string
    superstructure_material: string
    warranty: string
  }
  mechanical_equipment: {
    air_compressor: string
    air_conditioning: string
    antifouling_system: string
    batteries: string
    battery_chargers: string
    bow_thruster: string
    dirty_oil_pump: string
    emergency_generator: string
    engine_controls: string
    engine_mounts: string
    engine_room_ventilation: string
    engine_ventilation: string
    engines_exhaust_system: string
    fresh_water_makers: string
    fresh_water_pressure: string
    fresh_water_treatment: string
    fuel_filling_pump: string
    fuel_filters: string
    fuel_filtration: string
    fuel_oil_separator: string
    fuel_transfer_pump: string
    gear_boxes: string
    generator_hours: string
    generators: string
    grey_water_holding_tanks: string
    grey_water_system: string
    hot_and_cold_circulation_pump: string
    lubrication: string
    oil_filtration: string
    oil_pump: string
    output_electricity: string
    propellers: string
    propulsion_type: string
    rudders: string
    sewage_system: string
    shaft_log_and_acking_glands: string
    shafting: string
    shore_power: string
    stabilizers: string
    steering_system: string
    stern_thruster: string
    under_water_lights: string
    wc: string
    engine_1: {
      approx_hours: string
      date_hours_reg: string
      fuel: string
      horsepower: string
      last_service: string
      manufacturer: string
      model: string
      serial: string
      type: string
    }
    engine_2: {
      approx_hours: string
      date_hours_reg: string
      fuel: string
      horsepower: string
      last_service: string
      manufacturer: string
      model: string
      serial: string
      type: string
    }
  }
  navicom_equipment: {
    ais: string
    auto_pilot: string
    charts: string
    depth_sounder: string
    dgps: string
    echo_sounder: string
    electronic_chart_system: string
    eprib: string
    gdmss: string
    gps: string
    gyrocompass: string
    horn: string
    inmarsat_c: string
    log: string
    magnetic_compass: string
    navigation_bridge: string
    navtex: string
    radar: string
    radio: string
    rudder_angle_indicator_system: string
    satcom: string
    search_lights: string
    ships_computer: string
    ships_monitoring: string
    ssb: string
    telephone_system: string
    tender_tracking_system: string
    vdr: string
    vhf: string
  }
}

import { Checksums } from '@beacon/common/types'
import { _decodeContentFrontMatter } from './_decodeContentFrontMatter.js'
import * as GitHub from './github.js'

// const log = logger({ package: 'AhoyCMSProvider', f: 'createNewListings' })

const buildURI = (path: string, frontMatter: any) => {
  let _path = path
    .split('/') //
    .filter((p) => p !== 'content') // remove the 'content' directory
    .filter((p) => p !== 'index.html') // remove the 'index.html' file

  return `ahoy-cms::${_path.join('::')}::${frontMatter.listing_id}`
}

/**
 * Get the current listings (as URIs) from the CMS.
 *
 * @param bindings
 * @returns a list of the {URIs + checksum.etag} of the current listings
 */
export const getCurrentListingURIs = async (bindings: {
  GH_TOKEN: string
}): Promise<{ uri: string; checksums: Checksums }[]> => {
  //
  // 'ls content' directory to find 'yachts-for-sale' sha
  const yachtsForSaleTree = (await GitHub.getByPath(bindings, 'content', 'staging')).data //
    .find((d: any) => d.name === 'yachts-for-sale')
  // console.info(JSON.stringify(yachtsForSaleTree, null, 2))
  //  {
  //   "name": "yachts-for-sale",
  //   "path": "content/yachts-for-sale",
  //   "sha": "b5f5ff9d462f511ae13e88db9f8dd2441efc247a",
  //   "size": 0,
  //   "url": "https://api.github.com/repos/ahoyclub/homeport/contents/content/yachts-for-sale?ref=staging",
  //   "html_url": "https://github.com/ahoyclub/homeport/tree/staging/content/yachts-for-sale",
  //   "git_url": "https://api.github.com/repos/ahoyclub/homeport/git/trees/b5f5ff9d462f511ae13e88db9f8dd2441efc247a",
  //   "download_url": null,
  //   "type": "dir",
  //   "_links": {
  //     "self": "https://api.github.com/repos/ahoyclub/homeport/contents/content/yachts-for-sale?ref=staging",
  //     "git": "https://api.github.com/repos/ahoyclub/homeport/git/trees/b5f5ff9d462f511ae13e88db9f8dd2441efc247a",
  //     "html": "https://github.com/ahoyclub/homeport/tree/staging/content/yachts-for-sale"
  //   }
  // }

  //
  // get a git tree of the 'yachts-for-sale'; choose just the /index.html files (blobs)
  const listingContentsResponse = await GitHub.getTree(bindings, yachtsForSaleTree.sha, true)
  if (listingContentsResponse.data.truncated) {
    console.warn('The listing contents are truncated. This may cause some listings to be missing.')
  }
  const listingFiles = listingContentsResponse.data.tree //
    .filter((d: any) => d.type === 'blob')
    .filter((d: any) => d.path.endsWith('/index.html'))
  // console.info(JSON.stringify(listingFiles, null, 2))
  //  {
  //    "path": "2008-luhrs-31-open/index.html",
  //    "mode": "100644",
  //    "type": "blob",
  //    "sha": "e38d0e5fb87a823442dd0f4ccb423ab321688ea2",
  //    "size": 7149,
  //    "url": "https://api.github.com/repos/ahoyclub/homeport/git/blobs/e38d0e5fb87a823442dd0f4ccb423ab321688ea2"
  //  }

  //
  // Get the content of the files.
  const listingFilesContent = await Promise.all(
    listingFiles.map(
      async (f: any) => await GitHub.getByPath(bindings, `content/yachts-for-sale/${f.path}`, 'staging'),
    ),
  )
  // console.info(JSON.stringify(listingFilesContent, null, 2))

  //
  // Read the content and extract the front matter
  const entries = listingFilesContent //
    .map((f) => {
      let frontMatter = undefined
      let capturedError = undefined
      try {
        // The content is (generally) base64 encoded; need to decode it and parse.
        frontMatter = _decodeContentFrontMatter(f.data.content, f.data.encoding)
      } catch (err) {
        // parsing errors, just log and skip
        capturedError = err
      }

      // map some of the data
      return {
        path: f.data.path,
        etag: f.headers.etag,
        ...(frontMatter && { frontMatter: frontMatter }),
        ...(capturedError ? { error: capturedError } : {}),
      }
    })
    //
    // skip the ones with errors
    .filter((e) => {
      const pass = e.error === undefined
      !pass && console.warn('skipping error:', { path: e.path, error: JSON.stringify(e.error) })
      return pass
    })
    //
    // skip the entries that are drafts
    .filter((e) => {
      const pass = e.frontMatter && (e.frontMatter.draft === undefined || e.frontMatter.draft === false)
      !pass && console.info(`skipping ${e.path} (reason: is draft)`)
      return pass
    })
    //
    // skip the ones without a listing_id
    .filter((e) => {
      const pass = e.frontMatter && e.frontMatter.hasOwnProperty('listing_id')
      !pass && console.info(`skipping ${e.path} (reason: missing listing_id)`)
      return pass
    })
    //
    // create the URI.
    .map((e) => ({
      uri: buildURI(e.path, e.frontMatter),
      checksums: {
        etag: e.etag,
      },
    }))

  //
  // console.info(JSON.stringify(entries, null, 2))
  return entries
}

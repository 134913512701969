import { ObjectNotFoundFault } from '@beacon/common/lang/faults'
import { logger } from '@beacon/common/lang/log'
import { ListingInfo } from '@beacon/common/types'
import { asPath } from './uri'

const log = logger({ module: 'services', f: 'getListingInfo' })

/**
 * Get a feed listing document for the listing
 *
 * @param bindings
 * @param uriListing the beacon:: uri to the listing
 * @returns the feed document
 * @throws ObjectNotFoundFault if the feed document does not exist
 */
export const getListingInfo = async (
  bindings: { BEACON: R2Bucket; ID_CHART: KVNamespace },
  uriListing: string,
): Promise<ListingInfo> => {
  log.debug({ uriListing }, 'getting listing.json')

  let beaconUri = uriListing
  if (!uriListing.startsWith(`beacon::`)) {
    // a non-beacon uri was provided, deref it to get a beacon uri
    const result = await bindings.ID_CHART.get(uriListing, 'text')
    if (!result) {
      console.error(`could not find a value for '${uriListing}' in the ID_CHART`)
      throw new ObjectNotFoundFault(`does not exist: ${uriListing}`)
    }

    beaconUri = result
  }

  const r2Path = asPath(beaconUri, 'listing.json')
  const listing: R2ObjectBody | null = await bindings.BEACON.get(r2Path)
  if (!listing) {
    throw new ObjectNotFoundFault(`${r2Path}`)
  }

  return await listing.json()
}

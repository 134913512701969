//
export * from '../lang/assetMap'
export * from './BrochureListing'
export * from './SearchHit'

/**
 * The listing status of the Listing
 */
export enum ListingState {
  LISTED = 'Listed',
  SOLD = 'Sold',
}

// When content is fetched from a provider, carry this information back into the system.
export type ListingContent<T> = {
  uri: string
  body: T | string
  checksums?: Checksums
}

// Checksums to assist with determining if a file has changed.
export type Checksums = {
  md5?: string
  sha1?: string
  sha256?: string
  etag?: string
  lastModified?: string
}

// chronicle.json
export type Chronicle = {
  listed?: string // when was this listing created in the system (not the provider listing date!)
  lastUpdated?: string // when was the last time we updated the listing (i.e. wrote a new file)
  lastFetched?: string // when was the last time we fetched the listing from the provider (i.e. did a fetch)
  lastChecked?: string // when was the last time we checked the listing from the provider (i.e. ran a feed-fetcher process)
}

// listing.json
export type ListingInfo = {
  uri: string
  provider: string
  uriProvider: string
  uriBrochure: string
  checksums?: Checksums
}

export type Price = {
  amount: number // The value can be -1, which is converted to Price on Application on the client
  iso?: string
  plusGst?: boolean
}
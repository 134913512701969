/**
 * Functions to interact with the Yatco API
 */

import { logger } from '@beacon/common/lang/log'
import { YatcoListing } from '.'

const log = logger({ package: 'yatco', f: 'api' })

//
// Perform a fetch in a standard way.
//
const _fetch = async <T>(method: string, url: string, authToken: string, headers?: any, body?: any) => {
  log.debug({ method, url, authToken, headers, body }, 'fetch')

  const response = await fetch(url, {
    method: method,
    headers: {
      Authorization: `Basic ${authToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    ...(body && { body: JSON.stringify(body) }),
  })

  // sometimes they output 'null' as a response
  if (!response.ok || response.headers.get('content-length') === '4') {
    const body = await response.text()
    log.error({ status: response.status, statusText: response.statusText, body }, 'fetch failed')
    throw new Error(`Failed to fetch ${url}: ${response.status}`)
  }

  return (await response.json()) as T
}

//
export const forsaleVesselActiveVesselMLSID = async (auth: string): Promise<number[]> => {
  // [
  //   336399,
  //   412654,
  //   380473,
  //   394973,
  //   412453,
  //   ...
  // ]
  return _fetch<number[]>('GET', 'https://api.yatcoboss.com/api/v1/forsale/vessel/activevesselmlsid', auth)
}

//
export const commonListsGetAvailable = async (auth: string): Promise<{ ListTypeID: number; ListName: string }[]> => {
  // returns:
  // [
  //   {
  //       "ListTypeID": 1,
  //       "ListName": "VesselRig"
  //   },
  //   {
  //       "ListTypeID": 2,
  //       "ListName": "AccountType"
  //   },
  //   {
  //       "ListTypeID": 3,
  //       "ListName": "CountryState"
  //   },
  // ...
  // ]
  return _fetch('GET', 'https://api.yatcoboss.com/api/v1/common/Lists/GetAvailable', auth) as any
}

//
export const commonListsGetListById = async (auth: string, id: number) => {
  // [
  //   {
  //       "ListItemValue": 3483,
  //       "ListItemID": 3483,
  //       "ListTypeID": 1,
  //       "ParentID": 0,
  //       "ListItemDescription": "Cutter",
  //       "AltItemValue": "1307",
  //       "Visible": true,
  //       "SortOrder": 0,
  //       "NewItem": false
  //   },
  //   {
  //       "ListItemValue": 3485,
  //       "ListItemID": 3485,
  //       "ListTypeID": 1,
  //       "ParentID": 0,
  //       "ListItemDescription": "Schooner",
  //       "AltItemValue": "1309",
  //       "Visible": true,
  //       "SortOrder": 0,
  //       "NewItem": false
  //   },
  // ...
  // ]
  // https://api.yatcoboss.com/api/v1/common/Lists/typeid/:id
  return _fetch('GET', `https://api.yatcoboss.com/api/v1/common/Lists/typeid/${id}`, auth)
}

// Get GetVessel View_FullSpecs AllBy MLSID
export const getVesselViewFullSpecsAllByMLSID = async (auth: string, mlsid: number): Promise<YatcoListing> => {
  // {
  //   "isCharter": false,
  //   "Result": {
  //       "VesselID": 395758,
  //       "MLSID": 395758,
  //       "VesselStatus": 1,
  //       "VesselCondition": 2,
  //       "Year": 2012,
  //       "MainPhotoID": 3995709,
  //       "VesselType": 2,
  //       "ModelYear": 2012,
  //       "YearBuilt": 2012,
  //       "MainCategoryID": 2382,
  //       "MainCategoryText": "Motor Yacht",
  //       "StateRooms": 6,
  //       "Sleeps": 12,
  //       ...
  //   }
  // }
  return _fetch<YatcoListing>(
    'GET',
    `https://api.yatcoboss.com/api/v1/forsale/vessel/${mlsid}/details/fullspecsallbymlsid`,
    auth,
  )
}

// perform the forsale/vessel/search,
//  read all results
//  marshall results into expected format
export const forsaleVesselSearch = async (
  authToken: string,
  fields?: { [key: string]: any },
): Promise<any[]> => {
  // {
  //   "Results": [
  //     {
  //       "VesselID": 414113,
  //       "CompanyID": 130,
  //       "MLSID": 414113,
  //       "VesselStatus": 1,
  //       "VesselStatusText": "Active",
  //       ...
  //     }
  //   ],
  //   "Count": 41,   <- number of total results
  //   "Records": 12, <- ~number of records on this page
  //   "Offset": 0    <- offset into the results (not a page)
  // }

  type Result = { Results: YatcoListing[]; Count: number; Records: number; Offset: number }
  const ret: any[] = []

  let searchResult: Result
  do {
    // need to adjust Records and Offset
    const searchFields = { ...fields, Records: 64, Offset: ret.length == 0 ? 0 : ret.length - 1 }
    log.debug({ searchFields }, 'searching')
    //
    searchResult = await _fetch<Result>(
      'POST',
      'https://api.yatcoboss.com/api/v1/ForSale/Vessel/Search',
      authToken,
      {},
      searchFields,
    )
    //
    ret.push(...searchResult.Results)
    log.debug(
      { Count: searchResult.Count, Records: searchResult.Records, Offset: searchResult.Offset, have: ret.length },
      'searched',
    )

    // while:
    //  - we have a result (safety check)
    //  - we have not reached the expected number of results
  } while (searchResult && ret.length <= searchResult.Count)

  return ret
}

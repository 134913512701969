import { createRouter, createWebHistory } from 'vue-router'
import MainView from './views/MainView.vue'
import LayoutHeader from './views/LayoutHeader.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/auth/callback',
      name: 'auth-callback',
      component: () => import('./components/Loading.vue'),
    },
    {
      path: '/',
      name: 'home',
      components: {
        header: LayoutHeader,
        content: MainView,
      },
      props: { content: { navFilter: 'all' } },
    },
    {
      path: '/new/',
      name: 'new',
      components: {
        header: LayoutHeader,
        content: MainView,
      },
      props: { content: { navFilter: 'new' } },
    },
    {
      path: '/updated/',
      name: 'updated',
      components: {
        header: LayoutHeader,
        content: MainView,
      },
      props: { content: { navFilter: 'updated' } },
    },
    {
      path: '/sold/',
      name: 'sold',
      components: {
        header: LayoutHeader,
        content: MainView,
      },
      props: { content: { navFilter: 'sold' } },
    },
    // 404 page configuration by path matching
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('./views/404.vue'),
    },
    // Configure me for 401
    // {
    //   path: '/unauthorized',
    //   name: 'unauthorized',
    //   component: () => import('-----'),
    // },
  ],
  scrollBehavior(_to, _from, _savedPosition) {
    return {
      // always scroll to top on any route change.
      top: 0,
      behavior: 'smooth',
    }
  },
})

export default router

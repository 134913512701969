import { DateTime } from 'luxon'
import BoatIcon from '../assets/boat.svg'
import { Price } from '@beacon/common/types'

export const asRelativeTime = (epochSec: number | undefined) => {
  if (!epochSec) return undefined

  return DateTime.fromSeconds(epochSec).toRelative()
}

export const asYYYYLLLDD = (epochSec: number | undefined) => {
  if (!epochSec) return undefined

  return DateTime.fromSeconds(epochSec).toFormat('yyyy-LLL-dd').toUpperCase()
}

export const priceFull = (val: Price | undefined): string | undefined => {
  if (!val || !val.amount) {
    return undefined
  }
  if (val.amount === -1) {
    return 'POA'
  }
  const opts = {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
    signDislay: 'always',
  }
  // FIXME: this normalization should happen in the asIndexRecord()
  if (val.iso && val.iso.includes('AU')) {
    opts.currency = 'AUD'
  }
  return (
    new Intl.NumberFormat('en-US', {
      ...opts,
      ...(val.iso && val.iso.length == 3 && { currency: val.iso }),
    } as unknown as Intl.NumberFormatOptions)
      //
      .format(val.amount)
  )
}

/**
 * Show the price in a short format, e.g. '1.2M' instead of '1,200,000'
 * @param val
 * @returns
 */
export const priceShort = (val: Price | undefined): string | undefined => {
  if (!val || !val.amount) {
    return undefined
  }
  if (val.amount === -1) {
    return 'POA'
  }
  const opts = {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
    notation: 'compact',
    compactDisplay: 'short',
    signDislay: 'always',
  }
  // FIXME: this normalization should happen in the asIndexRecord()
  if (val.iso && val.iso.includes('AU')) {
    opts.currency = 'AUD'
  }
  return (
    new Intl.NumberFormat('en-US', {
      ...opts,
      ...(val.iso && val.iso.length == 3 && { currency: val.iso }),
    } as unknown as Intl.NumberFormatOptions)
      //
      .format(val.amount)
  )
}

export const setBrokenImageBlank = (e: Event) => {
  const target = e.target as HTMLImageElement
  const px =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
  target.src = px
}

export const setBrokenImagePlaceholder = (e: Event, classes = 'h-8 w-16 mr-2') => {
  const target = e.target as HTMLImageElement
  target.src = BoatIcon
  target.classList.value = classes
}

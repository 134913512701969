import { requestListingUpdate } from '@beacon/common/services'
import { findActiveProviderListings } from '@beacon/common/services/findActiveProviderListings'
import { DateTime } from 'luxon'
import { YatcoProvider } from '.'
import { fetchActiveURIsFromYatco, searchYatcoListings } from './_helper'

type ConfigRefreshListings = {
  searchFilters?: any
}

//
// Refresh the YATCO listings.
//
export const refreshListings = async (bindings: {
  BEACON: R2Bucket
  FEED_FETCH_QUEUE: Queue
  LISTING_ACTIVE: KVNamespace<string>
  YATCO_API_KEY: string
}) => {
  //
  const awaiting = []
  let config: ConfigRefreshListings = {}
  let configObj = await bindings.BEACON.get('provider/yatco/config/refreshListings.json')
  if (configObj !== null) {
    config = await configObj.json<ConfigRefreshListings>()
  }

  // search filters
  if (!config.searchFilters) {
    // first run, go back a day.
    const modifiedSince = DateTime.utc().minus({ days: 1 }).startOf('day').toISO()
    config.searchFilters = {
      ModifiedDate: modifiedSince,
    }
  }

  //
  // fetch the necessary data
  const [beaconActiveListings, yatcoActiveListings, searchResults] = await Promise.all([
    findActiveProviderListings(bindings, YatcoProvider.key),
    fetchActiveURIsFromYatco(bindings.YATCO_API_KEY),
    searchYatcoListings(bindings.YATCO_API_KEY, config.searchFilters),
  ])
  // as sets
  const _searchResults = new Set(searchResults)
  const _beaconActiveListings = new Set(beaconActiveListings)
  const _yatcoActiveListings = new Set(yatcoActiveListings)

  // Perform a search to find recently modified listings.
  //  SEARCH RESULTS intersection BEACON ACTIVE LISTINGS = listings to be fetched.
  const toFetch = Array.from(_searchResults.intersection(_beaconActiveListings))
  awaiting.push(requestListingUpdate(bindings, toFetch, { force: true }))

  // Mark these as checked
  //  (Beacon ACTIVE Listings intersection YATCO ACTIVE Listings) less SEARCH LISTINGS = listings we mark as "checked"
  const _toCheck = _beaconActiveListings.intersection(_yatcoActiveListings)
  for (const uri of toFetch) {
    _toCheck.delete(uri)
  }
  const toCheck = Array.from(_toCheck)
  awaiting.push(requestListingUpdate(bindings, toCheck, { force: true, lastChecked: DateTime.utc().toISO() }))

  // Update the refresh search config
  awaiting.push(bindings.BEACON.put(`provider/yatco/config/refreshListings.json`, JSON.stringify(config)))

  return Promise.allSettled(awaiting)
}

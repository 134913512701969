import { logger } from '@beacon/common/lang/log'
import { decodeBase64 } from '@beacon/common/lang/util'
import { frontMatterYamlToJSON } from './yamlParser'

const log = logger({ package: 'AhoyCMSProvider', f: '_decodeContentFrontMatter' })

/**
 * Given Base64-encoded content and encoding, decode the content and return the front matter
 *
 * @param base64Content
 * @param encoding
 * @returns
 */
export const _decodeContentFrontMatter = (base64Content: string, encoding: string) => {
  //
  if (encoding !== 'base64') {
    log.warn({ encoding }, 'unsupported encoding')
    throw new Error(`unsupported encoding: ${encoding}`)
  }

  const content = decodeBase64(base64Content)
  const frontMatter = frontMatterYamlToJSON(content)
  return frontMatter
}

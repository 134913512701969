import { logger } from '@beacon/common/lang/log'
import { AssetMap } from '@beacon/common/types'
import { asPath } from './uri'

const log = logger({ package: 'services', f: 'getAssetMap' })

/**
 * Get the asset map for the listing, empty if one does not exist.
 *
 * @param bindings
 * @param uriListing the beacon:: uri to the listing
 * @returns an AssetMap or an empty object if one does not exist.
 */
export const getAssetMap = async (bindings: { BEACON: R2Bucket }, uriListing: string): Promise<AssetMap> => {
  log.debug({ uriListing }, 'get asset map')
  const object: R2ObjectBody | null = await bindings.BEACON.get(asPath(uriListing, 'asset-map.json'))

  let assetMap
  if (object) {
    assetMap = new AssetMap(await object.json())
  } else {
    assetMap = new AssetMap()
  }

  return assetMap
}

import {
  Asset,
  AssetMap,
  BrochureListing,
  Chronicle,
  IndexHit,
  ListingContent,
  ListingInfo,
} from '@beacon/common/types'
import { AhoyCMSProvider } from './ahoy-cms'
import { BoatsOnlineProvider } from './boats-online'
import { YatcoProvider } from './yatco'

/**
 * Required type for a listing provider.
 *
 * @param B the bindings for the provider
 * @param C the content type for the provider
 */
export type ListingProvider<B, C> = {
  // a friendly name for the provider
  name: string

  // the key of the provider;
  //   must be unique in the code; used in URI / filesystem.
  key: string

  // map a listing to a brochure listing
  asBrochureListing: (inf: ListingInfo, content: ListingContent<C>, assetMap: AssetMap) => BrochureListing

  // map a listing to an (algolia) index hit
  asIndexHit: (inf: ListingInfo, content: ListingContent<C>, chron: Chronicle, assetMap: AssetMap) => IndexHit

  // create new listings which this provider may have
  createNewListings: (bindings: B) => Promise<any>

  // extract any assets contained in a listing
  extractAssets: (content: ListingContent<C>) => Asset[]

  // fetch a listing from the source
  fetchListingContent(bindings: B, uri: string): Promise<ListingContent<C>>

  // refresh existing listings
  refreshListings: (bindings: B, force?: boolean) => Promise<any>
}

// Define the providers.
export const Providers: { [k: string]: ListingProvider<any, any> } = {
  [AhoyCMSProvider.key]: AhoyCMSProvider,
  [BoatsOnlineProvider.key]: BoatsOnlineProvider,
  [YatcoProvider.key]: YatcoProvider,
}

import { ListingNotFoundFault } from '@beacon/common/lang/faults'
import { ListingContent } from '@beacon/common/types'
import { _decodeContentFrontMatter } from './_decodeContentFrontMatter'
import * as GitHub from './github'
import { AhoyCMSListing } from './index'

export const fetchListingContent = async (
  bindings: { GH_TOKEN: string },
  uri: string,
): Promise<ListingContent<AhoyCMSListing>> => {
  //
  const uriParts = uri.split('::')
  const path = uriParts.slice(1, uriParts.length - 1).join('/')

  let listingFile
  let frontMatter
  try {
    listingFile = await GitHub.getByPath(bindings, `content/${path}/index.html`, 'staging')
    frontMatter = _decodeContentFrontMatter(listingFile.data.content, listingFile.data.encoding)
  } catch (err: any) {
    throw new ListingNotFoundFault(uri, err.message)
  }

  //
  // checks; not draft and must have listing_id
  if (
    (frontMatter.draft !== undefined && frontMatter.draft !== false) || //
    !frontMatter.hasOwnProperty('listing_id')
  ) {
    throw new ListingNotFoundFault(uri, 'invalid fields on listing provider')
  }

  //
  const listing = {
    uri: uri,
    body: JSON.stringify(frontMatter),
    checksums: {
      etag: listingFile.headers.etag,
    },
  }

  // console.debug(`fetched listing ${uri}: ${JSON.stringify(listing)}`)
  return listing
}

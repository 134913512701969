import { forsaleVesselActiveVesselMLSID, forsaleVesselSearch } from './_api'
import { YatcoProvider } from './index'

/**
 * Get a list of active URIs from YATCO.
 *
 * @param apiToken
 * @returns A list of active YATCO URIs
 */
export const fetchActiveURIsFromYatco = async (apiToken: string): Promise<string[]> => {
  const activeMLSIDs = await forsaleVesselActiveVesselMLSID(apiToken)
  return activeMLSIDs.map((id: number) => `${YatcoProvider.key}::mls::${id}`)
}

/**
 * Search and return URIs from YATCO.
 *
 * @param apiToken
 * @returns a list of URIs returned from the search in `yatco::mls::<MLSID>` format
 */
export const searchYatcoListings = async (apiToken: string, filters: any): Promise<string[]> => {
  const searchResults: any[] = await forsaleVesselSearch(apiToken, filters)
  return searchResults //
    .filter((e) => !!(e.MLSID)) // make sure we have an MLSID
    .map((e) => `${YatcoProvider.key}::mls::${e.MLSID}`)
}

<script setup lang="ts">
import { IndexHit } from '@beacon/common/types'
import debounce from 'lodash.debounce'
import { DateTime } from 'luxon'
import { computed, ref, Ref } from 'vue'
import SearchResultsObserver from './SearchResultsObserver.vue'

const hits: Ref<IndexHit[]> = ref([])

type Props = {
  navFilter: string
}
const props = defineProps<Props>()

//
// manage the filter that is supplied via the route
const numericFilters = computed(() => {
  const epoch30DaysAgo = DateTime.utc().minus({ days: 30 }).toSeconds()
  switch (props.navFilter) {
    case 'new':
      return [`tsListingPublished>=${epoch30DaysAgo}`]
    case 'updated':
      return [`tsListingUpdated>=${epoch30DaysAgo}`]
    default:
      return []
  }
})
const facetFilters = computed(() => {
  switch (props.navFilter) {
    case 'sold':
      return [`labels:sold`]
    default:
      return []
  }
})

const searchFilter = computed(() => {
  return [...numericFilters.value, ...facetFilters.value].join(' AND ')
})

//
// When the 'river' observer becomes visible, fetch the next set of results
const visibilityChanged = debounce((isVisible: boolean, _entry: any, refineNext: any) => {
  if (isVisible) {
    refineNext()
  }
}, 200)

//
// use the 'transformHits' function to append new hits to the existing list
const transformHits = (items: IndexHit[], { results }: any) => {
  // Receives the items and is called before displaying them. It returns a new
  // array with the same “shape” as the original. This is helpful when transforming,
  // removing, or reordering items.

  if (results.page === 0) {
    // reset hits to empty array if it's the first page
    hits.value = []
    window.document.getElementById('searchResults-top')?.scrollIntoView(false)
  }

  items.forEach((item) => {
    if (!hits.value.some((hit) => hit.objectID === item.objectID)) {
      hits.value.push(item)
    }
  })

  return hits.value
}
</script>
<template>
  <!-- Setup results ("hits") -->
  <ais-configure :hits-per-page.camel="50" :filters="searchFilter" />
  <ais-infinite-hits :transform-items="transformHits" v-bind="$attrs">
    <template v-slot="{ refineNext, isLastPage, results }">
      <template v-if="!results.nbHits">
        <slot name="no-results">
          <h4 class="font-bold text-center text-black dark:text-white">No results found.</h4>
        </slot>
      </template>
      <template v-else>
        <div id="searchResults-top"></div>
        <slot name="header"></slot>
        <slot
          name="main"
          :hits="hits"
          :visibilityChanged="visibilityChanged"
          :refineNext="refineNext"
          :isLastPage="isLastPage"
        >
          <main class="flex-1 flex-col overflow-auto">
            <template v-for="(hit, index) in hits" :key="hit.objectID">
              <slot name="hit" :hit="hit">
                <div>{{ index }}. {{ hit.name }}</div>
              </slot>
            </template>
            <template v-if="!isLastPage">
              <SearchResultsObserver :refineNext="refineNext" @visibilityChanged="visibilityChanged" />
            </template>
            <template v-else="isLastPage">
              <slot name="endOfResults">
                <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
              </slot>
            </template>
          </main>
        </slot>
        <slot name="footer"></slot>
      </template>
    </template>
  </ais-infinite-hits>
</template>

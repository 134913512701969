import { commonListsGetAvailable, commonListsGetListById } from './_api'

/**
 * Refresh the data "lists":
 *  See https://api.yatco.com/#f3282d46-0a71-4222-aff1-203a39be1a84
 *
 * @param env
 */
export const refreshCommonLists = async (bindings: { BEACON: R2Bucket; YATCO_API_KEY: string }) => {
  const lists = await commonListsGetAvailable(bindings.YATCO_API_KEY)

  const awaiting: Promise<any>[] = []

  //
  for (const list of lists) {
    // get the list data
    const data = await commonListsGetListById(bindings.YATCO_API_KEY, list.ListTypeID)
    awaiting.push(bindings.BEACON.put(`provider/yatco/common/lists/${list.ListName}.json`, JSON.stringify(data)))
  }

  await Promise.allSettled(awaiting)
  return Promise.resolve()
}

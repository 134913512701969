<script setup lang="ts">
import { BellIcon, SparklesIcon } from '@heroicons/vue/20/solid'
import FileInvoiceDollarIcon from '../icons/FileInvoiceDollarIcon.vue'
import ShipIcon from '../icons/ShipIcon.vue'
import NavLink from '../components/NavLink.vue'
import humanFormat from 'human-format'
import debounce from 'lodash.debounce'

const navigation = [
  { name: 'All Listings', href: '/', icon: ShipIcon },
  { name: 'New Listings', href: '/new/?global%5BsortBy%5D=global_new_desc', icon: SparklesIcon },
  { name: 'Updated Recently', href: '/updated/?global%5BsortBy%5D=global_updated_desc', icon: BellIcon },
  { name: 'Sold Recently', href: '/sold/?global%5BsortBy%5D=global_updated_desc', icon: FileInvoiceDollarIcon },
]

// pick a refinement value.
// when the value is equivlanent to the range value, return an empty string
// follows the logic in the demo here:
//  https://www.algolia.com/doc/api-reference/widgets/range-input/vue/
const pick = (current: any, range: any) => {
  if (current && current !== range) {
    return current
  }
  return ''
}

// convert a number to an abbreviated number.
const abbrev = (num: number | undefined): string => {
  if (!num) return num as unknown as string
  return humanFormat(num, { separator: '' })
}

// convert an abbreviated number to an actual amount
const asNumber = (str: string) => {
  if (!str) return str
  // correct input for this:
  //  1.2M -> 1200000 (1.2m = 1.2 meters)
  //  1.2k -> 1200 (1.2K = error)
  const _input = str.replace(/m/, 'M').replace(/K/, 'k')
  return humanFormat.parse(_input)
}

//
const debounceRefine = debounce((refine: any, a: any) => refine(a), 600)
</script>
<template>
  <nav class="flex flex-1 flex-col">
    <ul role="list" class="flex flex-1 flex-col gap-y-7">
      <!-- static menu items -->
      <li>
        <ul role="list" class="-mx-2 space-y-1">
          <li v-for="item in navigation" :key="item.name">
            <NavLink
              :to="item.href"
              class="group flex gap-x-3 rounded-md p-2 text-sm leading-6"
              activeClass="bg-ahoy-club-teal-secondary text-white"
              inactiveClass="text-indigo-200 hover:bg-ahoy-club-teal-secondary hover:text-white"
            >
              <template v-slot="{ isActive }">
                <component
                  :is="item.icon"
                  :class="[isActive ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0']"
                  aria-hidden="true"
                />
                {{ item.name }}
              </template>
            </NavLink>
          </li>
        </ul>
      </li>

      <li class="pt-8">
        <ul role="list" class="-mx-2 space-y-1 text-indigo-200">
          <ais-clear-refinements :included-attributes="['length', 'priceAsking.amount']">
            <template v-slot="{ canRefine, refine, createURL }">
              <li
                :class="[
                  'group flex gap-x-3 rounded-md p-2 text-xs leading-6',
                  canRefine ? 'bg-ahoy-club-teal-secondary text-gray-50' : 'bg-gray-800',
                ]"
              >
                <div class="flex flex-row w-full">
                  <div class="uppercase flex-grow">Refinements</div>
                  <div class="uppercase text-[10px] align-top">
                    <a :href="createURL()" @click.prevent="refine" v-if="canRefine">[ RESET ]</a>
                    <span v-else><!-- if always needs to render some content or it renders internal content --></span>
                  </div>
                </div>
              </li>
            </template>
          </ais-clear-refinements>
          <li>
            <div class="text-sm p-2 flex flex-col gap-2">
              <div>Length (m)</div>
              <ais-range-input attribute="length">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <input
                    class="w-1/3 placeholder:italic placeholder:text-gray-400 text-gray-600 border border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    type="number"
                    :min="range.min"
                    :max="range.max"
                    :placeholder="range.min"
                    :disabled="!canRefine"
                    :value="pick(currentRefinement.min, range.min)"
                    @focusout="
                      refine({
                        min: ($event.currentTarget as HTMLInputElement)!.value,
                        max: pick(currentRefinement.max, range.max),
                      })
                    "
                    @input="
                      debounceRefine(refine, {
                        min: ($event.currentTarget as HTMLInputElement)!.value,
                        max: pick(currentRefinement.max, range.max),
                      })
                    "
                  />
                  →
                  <input
                    class="w-1/3 placeholder:italic placeholder:text-gray-400 text-gray-600 border border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    type="number"
                    :min="range.min"
                    :max="range.max"
                    :placeholder="range.max"
                    :disabled="!canRefine"
                    :value="pick(currentRefinement.max, range.max)"
                    @focusout="
                      refine({
                        min: pick(currentRefinement.min, range.min),
                        max: ($event.currentTarget as HTMLInputElement)!.value,
                      })
                    "
                    @input="
                      debounceRefine(refine, {
                        min: pick(currentRefinement.min, range.min),
                        max: ($event.currentTarget as HTMLInputElement)!.value,
                      })
                    "
                  />
                </template>
              </ais-range-input>
            </div>
          </li>
          <li>
            <div class="text-sm p-2 flex flex-col gap-2">
              <div>Budget</div>
              <ais-range-input attribute="priceAsking.amount" :precision="3">
                <template v-slot="{ currentRefinement, range, canRefine, refine }">
                  <input
                    class="w-1/3 placeholder:italic placeholder:text-gray-400 text-gray-600 border border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    :disabled="!canRefine"
                    :min="range.min"
                    :max="range.max"
                    :placeholder="abbrev(range.min)"
                    :value="abbrev(pick(currentRefinement.min, range.min))"
                    @focusout="
                      refine({
                        min: asNumber(($event.currentTarget as HTMLInputElement)!.value),
                        max: pick(currentRefinement.max, range.max),
                      })
                    "
                    @input="
                      debounceRefine(refine, {
                        min: asNumber(($event.currentTarget as HTMLInputElement)!.value),
                        max: pick(currentRefinement.max, range.max),
                      })
                    "
                  />
                  →
                  <input
                    class="w-1/3 placeholder:italic placeholder:text-gray-400 text-gray-600 border border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    :disabled="!canRefine"
                    :min="range.min"
                    :max="range.max"
                    :placeholder="abbrev(range.max)"
                    :value="abbrev(pick(currentRefinement.max, range.max))"
                    @focusout="
                      refine({
                        min: pick(currentRefinement.min, range.min),
                        max: asNumber(($event.currentTarget as HTMLInputElement)!.value),
                      })
                    "
                    @input="
                      debounceRefine(refine, {
                        min: pick(currentRefinement.min, range.min),
                        max: asNumber(($event.currentTarget as HTMLInputElement)!.value),
                      })
                    "
                  />
                </template>
              </ais-range-input>
            </div>
          </li>
        </ul>
      </li>

      <!-- SPACE -->
      <!-- bottom -->
      <!-- li class="mt-auto">
        <a
          href="#"
          class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
        >
          <Cog6ToothIcon class="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white" aria-hidden="true" />
          Settings
        </a>
      </li -->
    </ul>
  </nav>
</template>

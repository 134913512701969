import { Price } from '@beacon/common/types/index'

const supportedCurrencies = [
  {
    iso: 'AUD',
    symbol: '$',
  },
  {
    iso: 'USD',
    symbol: '$',
  },
  {
    iso: 'EUR',
    symbol: '€',
  },
  {
    iso: 'NZD',
    symbol: '$',
  },
]

/**
 * Return a Price object that contains the amount, iso and symbol separately
 *
 * @param value A price string field containing the price and the currency together
 */
export const transformPrice = (value?: string): Price | undefined => {
  if (!value) {
    return
  }

  const price = parseInt(value.replace(/\D/g, '')) || -1 // 'Price on Application'
  const currency = value.replace(/[0-9, ]/g, '')
  const isGst =
    value.toLowerCase().includes('gst') || value.toLowerCase().includes('vat') || value.toLowerCase().includes('tax')

  const matchedCurrency =
    supportedCurrencies.filter((c) => {
      if (currency.includes(c.symbol) && c.iso === 'EUR') {
        return c
      }

      const val = currency.replace('$', '').trim()
      if (val.includes(c.iso) || c.iso.includes(val)) {
        return c
      }

      return null
    })[0] || null

  return {
    amount: price,
    ...(matchedCurrency && {
      iso:
        matchedCurrency.iso === 'AU'
          ? 'AUD'
          : matchedCurrency.iso === 'NZ'
            ? 'NZD'
            : matchedCurrency.iso === 'US'
              ? 'USD'
              : matchedCurrency.iso,
    }),
    plusGst: isGst,
  }
}

import createKindeClient, { KindeClient, KindeClientOptions, KindeUser } from '@kinde-oss/kinde-auth-pkce-js'
import { App } from 'vue'

/**
 * Create a plugin for the Vue App to use the Kinde authentication client.
 *
 * @returns a plugin to install.
 */
export async function createAuthentication(initOptions: KindeClientOptions & { org_code?: string }) {
  /**
   * init the Kinde Client immediately!
   */
  const kindeClient: KindeClient = await createKindeClient(initOptions)

  /**
   * Send the user to the login page.
   */
  const redirectToLogin = (options: any = {}): Promise<void> => {
    const loginOptions = {
      app_state: {
        // keep the state (path only, no host) so that the user is redirected back to where they started
        redirectTo: window.location.pathname,
      },
      org_code: initOptions?.org_code,
      ...options,
    }
    return kindeClient.login(loginOptions)
  }

  /**
   * Send the user to the logout flow.
   */
  const logout = (): Promise<void> => {
    return kindeClient.logout()
  }

  /**
   * Get the user information
   * @returns the KindeUser
   */
  const getUser = (): KindeUser => {
    const user: KindeUser = kindeClient.getUser()
    return user
  }

  /**
   *
   * @returns the token or undefined if the token is not available.
   */
  const getToken = async (): Promise<string | undefined> => {
    const token = await kindeClient.getToken()
    if (token) {
      return token
    }

    // no token.
    await redirectToLogin()
    // in theory, this promise reject should never occur as the redirect should happen before.
    return Promise.reject('No token available')
  }

  /**
   * The plugin to install in the Vue App.
   *
   * This exposes the kinde client and user information in a global.
   */
  const plugin = {
    redirectToLogin,
    logout,
    getUser,
    getToken,
    install: (app: App): void => {
      // global properties are available in Vue <template> sections.
      app.config.globalProperties._kindeClient = kindeClient
      app.config.globalProperties.$user = getUser()
      app.config.globalProperties.$getToken = async () => getToken()
      // to access this data in a <script setup>, you need to use the inject API.
      app.provide('authenticationContext', plugin)
    },
  }

  return plugin
}
